import { Link } from "react-router-dom";
import styles from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={styles.heroSection}>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>Green Energy Innovation</h1>
        <p className={styles.heroSubtitle}>
          Shape the future of sustainable energy with your innovative ideas.
          Together, we can build a cleaner, more sustainable world through
          technology.
        </p>
        <Link to='/register'>
          <button className={styles.createButton}>Create Your Idea</button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
