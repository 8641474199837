import styles from "./components.module.css";

const SubmitButton = ({ text, handleClick }) => {
  return (
    <button className={styles.submitBtn} type="submit" onClick={handleClick}>
      {text}
    </button>
  );
};

const GoBackButton = ({ step, setStep, text, region }) => {
  const handleClick = () => {
    if (region !== "egypt" && step == 3) setStep(2);
    setStep((step) => step - 1);
  };
  return (
    <button className={styles.goBackBtn} onClick={handleClick}>
      {text}
    </button>
  );
};

const AddButton = ({ inputRef }) => {
  const triggerSpaceKey = () => {
    if (inputRef.current) {
      const event = new KeyboardEvent("keydown", {
        key: " ",
        keyCode: 32,
        code: "Space",
        bubbles: true,
      });

      inputRef.current.dispatchEvent(event);
    }
  };
  return (
    <div className={styles.addBtn} onClick={triggerSpaceKey}>
      <img src="assets/2025/registration/plus.svg" alt="" />
    </div>
  );
};

export { SubmitButton, GoBackButton, AddButton };
