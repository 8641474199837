import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import GetStartedForm from "./GetStartedForm/GetStartedForm";
import RegionFormInfo from "./components/FormRegionInfo";
import Consent from "./Consent/Consent";
import PitchForm from "./PitchForm/PitchForm";
import Imagery from "./Imagery/Imagery";
import Submission from "./Submission/Submission";
import {
  GetStartedFormStatement,
  CreateAccountFormStatement,
  ConsentFormStatement,
  PitchFormStatement,
} from "./components/FormStatements";

import { StepContext } from "./contexts/stepContext";
import { VerificationProvider } from "./contexts/verificationContext";
import { MainUserContext } from "../../../contexts/mainUserContext";

import styles from "./Register.module.css";

const Register = () => {
  const [step, setStep] = useContext(StepContext);
  const [userDetails, setUserDetails] = useContext(MainUserContext);

  const navigate = useNavigate();
  const { region, isPitchSubmitted, isSubmissionSucc, failedSubmissionMsg } =
    userDetails;

  const steps = [
    {
      form: <GetStartedForm />,
      imagery: "getStarted",
      header: "GET STARTED",
      statement: <GetStartedFormStatement />,
    },
    {
      form: <RegionFormInfo region={region} />,
      imagery: "personalInfo",
      header: "Create An Account",
      statement: <CreateAccountFormStatement />,
    },
    {
      form: <Consent />,
      imagery: "consent",
      header: "Consent",
      statement: <ConsentFormStatement />,
    },
    {
      form: <PitchForm />,
      imagery: "pitch",
      header: "Submit Your Pitch",
      statement: <PitchFormStatement />,
    },
  ];

  const currentStep = steps[step];

  /* Handlers */
  const handleBackToHome = () => {
    navigate("/");
  };

  const handleBackToRegistration = () => {
    setUserDetails({ ...userDetails, isPitchSubmitted: false });

    if (
      failedSubmissionMsg.includes("mail") ||
      failedSubmissionMsg.includes("hone number")
    )
      setStep(1);
      else setStep(3);
  };

  return !isPitchSubmitted ? (
    <VerificationProvider>
      <div className={styles.registerContainer}>
        <div className={styles.formContainer}>
          <h1 className={styles.formHeader}>{currentStep.header}</h1>
          <div className={styles.formStatement}>{currentStep.statement}</div>
          {currentStep.form}
        </div>
        <Imagery imageName={currentStep.imagery} />
      </div>
    </VerificationProvider>
  ) : isSubmissionSucc ? (
    <Submission
      header={"Congratulations"}
      details={"Please check your email, confirmation will be received soon."}
      imageName={"succSubm"}
      btnAction={"Back to Home"}
      handleClick={handleBackToHome}
    />
  ) : (
    <Submission
      header={"Something went wrong"}
      details={failedSubmissionMsg}
      imageName={"failedSubm"}
      btnAction={"Back to Registration"}
      handleClick={handleBackToRegistration}
    />
  );
};

export default Register;
