import styles from "./Prize.module.css";
import { WhiteDiamond } from "../_shared/components/shapes";
import { isMobile } from "react-device-detect";

const PrizeDetails = ({ winnerPlaceText, amount, bgImg }) => {
  const height = isMobile ? 10 : 16;
  const width = isMobile ? 10 : 16;

  return (
    <div className={styles.winner}>
      <img
        src={`assets/2025/home/${bgImg}`}
        style={{ position: "absolute" }}
        alt=""
      />
      <h4 className={styles.winnerPlaceText}>{winnerPlaceText}</h4>
      <div className={styles.prizeDetailsPerPlace}>
        <WhiteDiamond />
        <div style={{ fontSize: 32, fontWeight: 600 }}>EUR {amount}</div>
        <WhiteDiamond />
      </div>
    </div>
  );
};

const Prize = () => {
  return (
    <div className={styles.prizeContainer}>
      <h1 className={styles.prizeHeader}>What is in it for you?</h1>
      <p className={styles.paragraph}>
        Take your skills and expertise to the next level and enjoy creating
        innovative solutions whilst being coached and inspired by our experts.
        You will boost your skills, grow your professional network and have a
        chance to participate in the Vodafone’s Global Hackathon
        “Hack-alympics”, interact with VOIS Leadership and earn cash prizes!
      </p>
      <p className={styles.paragraph}>
        In our hackathon, brilliance deserves recognition. We've lined up an
        array of captivating prizes that await those who dare to innovate, those
        who dream big and code even bigger.
      </p>
      <div className={styles.prizes}>
        <PrizeDetails
          winnerPlaceText="FINALE WINNER"
          amount={6000}
          bgImg={"1.svg"}
        />
      </div>
      <div className={styles.runnerUps}>
        <PrizeDetails
          winnerPlaceText={"FINALE 1ST RUNNER-UP"}
          amount={6000}
          bgImg={"2.svg"}
        />

        <PrizeDetails
          winnerPlaceText={"FINALE 2ND RUNNER-UP"}
          amount={6000}
          bgImg={"3.svg"}
        />
      </div>
    </div>
  );
};

export default Prize;
