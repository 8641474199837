const videosSrcs = {
  video1:
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7064907477254815744?compact=1",
  video2:
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7132644258527739905?compact=1",
  video3:
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7127278454718251008?compact=1",
  video4:
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7125045535358488576?compact=1",
  video5:
    "https://hackathon.tvois.vodafone.com/hack24/VOIS%20of%20change%2003_VOIS_LL_-_VOIS_of_Change_Module_03_1.mp4",
  video6:
    "https://hackathon.tvois.vodafone.com/hack24/Life%20at%20VOIS_%20Culture_202208VV0001_V6_1.mp4",
};

export { videosSrcs };
