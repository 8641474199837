import axios from "axios";
import { appendFormData } from "../helpers/helpers";

const BASEURL = `${process.env.REACT_APP_SERVER_URL}`;

export const getCodebyEmail = (email) =>
  axios.post(`${BASEURL}registerverification/getvermail`, { email });

export const getCodebyMobile = (mobile, region) =>
  axios.post(`${BASEURL}registerverification/getvermobile`, { mobile, region });

export const verifyMobileCode = (mobile, code) =>
  axios.post(`${BASEURL}registerverification/verifymobile`, { mobile, code });

export const verifyEmailCode = (mail, code) =>
  axios.post(`${BASEURL}registerverification/verifymail`, { mail, code });

export const submitProject = async (obj) => {
  return axios
    .post(`${BASEURL}project/submitproject`, obj, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res);
    });
};

export const submitPitch = async (finalObj) => {
  const formData = new FormData();
  appendFormData(formData, finalObj);

  await axios.post(`${BASEURL}project/submitproject`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
