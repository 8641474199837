import { useContext, useState } from "react";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import styles from "./Consent.module.css";
import { Checkbox } from "@mui/material";
import { GoBackButton, SubmitButton } from "../components/Button";
import { StepContext } from "../contexts/stepContext";

import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";

const today = new Date();
const formattedDate = `${String(today.getMonth() + 1).padStart(
  2,
  "0"
)}/${String(today.getDate()).padStart(2, "0")}/${today.getFullYear()}`;

const convertDivToPDF = async (divID) => {
  try {
    const dataUrl = await htmlToImage.toPng(document.getElementById(divID), {
      quality: 1,
    });

    const PDF = new jsPDF();
    const imgProps = PDF.getImageProperties(dataUrl);
    const pdfWidth = PDF.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    PDF.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);

    const pdfBlob = new Blob([PDF.output("blob")], { type: "application/pdf" });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onload = () => resolve(reader.result); // Return the base64 data
      reader.onerror = (error) => reject(error);
    });
  } catch (error) {
    console.error("Error generating PDF:", error);
    return null;
  }
};

const RevokeLink = () => {
  return <a href="https://hackathon.tvois.vodafone.com/revoke">الرابط</a>;
};

const ConsentText = ({ name, nationalID }) => {
  return (
    <div className={styles.consentDetails}>
      أقر أنا {name} بطاقة رقم قومي {nationalID} بأنني وبكامل ارادتى الحرة أوافق
      موافقة صريحة على تقديم بياناتى الشخصية لشركة فودافون انترناشيونال سيرفيزيس
      ش.ذ.م.م أو من ينوب عنها، و استخدامها من قبل الشركه التى تشمل على سبيل
      المثال لا الحصر الاسم و العنوان و رقم التليفون و السيرة الذاتية و صور
      شخصية (اختيارية) و فكرة المشروع المطروحة و ملف فيديو يشمل الفكرة المطروحة
      و كذلك ملفات الفيديو المصورة لدى شركة فودافون انترناشيونال سيرفيزيس
      ش.ذ.م.م خلال احداث و فاعليلات الهاكاثون ، كما أقر بعلمى التام بأن بيناتى
      الشخصية سيتم التعامل معها بس من خلال شركة فودافون انترناشيونال سيرفيزيس
      ش.ذ.م.م أو من ينوب خلال فترة الهاكاثون و لفترة لاحقة كلما هو موضح تفصيلا
      فى الفقرات السابقة من هذا الاقرار. ويعتبر هذا الإقرار منتجا لكافة آثاره
      القانونية ما لم أتقدم بطلب إلى الشركة بطلب سحب هذه الموافقة وذلك من خلال
      هذا {<RevokeLink />} . ولا يخل هذا الإقرار بأية حقوق أخرى مقررة للشركة.
    </div>
  );
};

const UserInfoItem = ({ label, data }) => {
  return (
    <div className={styles.userInfoItem}>
      <label>{label}</label> {"  "}
      {data}
    </div>
  );
};

const ConsentCheck = ({ consented, setConsented }) => {
  const [userDetails] = useContext(MainUserContext);
  const handleChange = () => {
    setConsented((prevState) => !prevState);
  };
  return (
    <div className={styles.consentCheck}>
      <Checkbox
        checked={userDetails.consentCheck || consented}
        onChange={handleChange}
        sx={{
          borderColor: "#e60000",
          color: "#b8b8b8",
          "&.Mui-checked": {
            color: "#e60000",
          },
        }}
      />
      <label>I have read and agree to the Consent.</label>
    </div>
  );
};

const Consent = () => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const [step, setStep] = useContext(StepContext);
  const { arabicFullName, nationalID, region } = userDetails;

  const [consented, setConsented] = useState(false);

  const userInfoList = [
    { label: "الاسم: ", data: arabicFullName },
    { label: "التاريخ: ", data: formattedDate },
    { label: "التوقيع: ", data: arabicFullName },
  ];

  const handleToFinalStep = () => {
    if (consented || userDetails.consentCheck) {
      convertDivToPDF("consentDoc").then((pdfBase64) => {
        setUserDetails({
          ...userDetails,
          consentCheck: true,
          PDFConsent: pdfBase64,
        });
      });
      setStep(3);
    }
  };

  return (
    <div className={styles.consentContainer}>
      <div id="consentDoc" className={styles.consentDetailsContainer}>
        <h4 className={styles.consentHeader}>
          إقرار بالموافقة لإستخدام البيانات الشخصية
        </h4>

        <ConsentText name={arabicFullName} nationalID={nationalID} />

        <div className={styles.userInfo}>
          {userInfoList.map((item) => (
            <UserInfoItem label={item.label} data={item.data} />
          ))}
        </div>
      </div>
      <ConsentCheck consented={consented} setConsented={setConsented} />
      <div className={styles.btnsGp}>
        <SubmitButton text={"Final Step"} handleClick={handleToFinalStep} />
        <GoBackButton
          text={"Back"}
          step={step}
          setStep={setStep}
          region={region}
        />
      </div>
    </div>
  );
};

export default Consent;
