import { NavLink } from "react-router-dom";
import { HorizontalRedLine, SocialMediaIcon } from "../imgsAndSVGs/ImgsAndSVGs";
import { openLink } from "../../helpers/helpers";

import styles from "./AgendaFooter.module.css"

const externalLinks = {
  VOISFB: "https://www.facebook.com/voisglobal/",
  VOISIG: "https://www.instagram.com/voisglobal/",
  VOISLI: "https://www.linkedin.com/company/vois/mycompany/verification/",
  VOISCareers:
    "https://www.vodafone.com/careers/professional-career-areas/shared-services#Work-with-us",
};

const FooterNavLink = ({ name, to }) => {
  return (
    <NavLink className={styles["left-section-content"]} to={to}>
      {name}
    </NavLink>
  );
};

const AgendaFooter = () => {
  return (
    <div className={styles["footer-main-div"]}>
      <HorizontalRedLine />
      <div className={styles["footer-content"]}>
        <div className={styles["left-section"]}>
          <FooterNavLink to="/faq" name={"FAQs"} />
          <FooterNavLink to="/guidelines" name={"Guidelines"} />
          <span
            className={styles["left-section-content"]}
            onClick={() => openLink(externalLinks.VOISCareers)}
          >
            Careers
          </span>
        </div>
        <div className={styles["right-section"]}>
          <SocialMediaIcon styles={styles} platformName={"LI"} link={externalLinks.VOISLI} />
          <SocialMediaIcon styles={styles} platformName={"IG"} link={externalLinks.VOISIG} />
          <SocialMediaIcon styles={styles} platformName={"FB"} link={externalLinks.VOISFB} />
        </div>
      </div>
    </div>
  );
};

export default AgendaFooter;