import { useContext, useEffect, useRef, useState } from "react";
import { AddButton, GoBackButton, SubmitButton } from "../components/Button";
import { StepContext } from "../contexts/stepContext";

import styles from "./PitchForm.module.css";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { PitchFormScheme } from "../validationShcema/PitchFormYupSchema";
import * as Yup from "yup";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import { getErrorMsg, handleSubmittedPitch } from "../helpers/helpers";
import { submitPitch, submitProject } from "../apis/APIs";

const UploadPresentationFile = () => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const [fileName, setFileName] = useState("Click to browse your local drive");
  const [error, setError] = useState("");

  // Allowed file types and their MIME types
  const allowedFileTypes = {
    "application/pdf": "PDF",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "PowerPoint",
    "application/zip": "ZIP",
    "video/mp4": "MP4 Video",
  };

  const validateFileType = (file) => {
    return Object.keys(allowedFileTypes).includes(file.type);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Validate file size
    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > 100) {
      setError("File size must be less than 100 MB");
      return;
    }

    // Validate file type
    if (!validateFileType(file)) {
      setError(
        `Invalid file type. Allowed types: ${Object.values(
          allowedFileTypes
        ).join(", ")}`
      );
      return;
    }

    setFileName(file.name);
    setError("");
    setUserDetails({ ...userDetails, presentationFile: file });
  };

  return (
    <div className={styles.inputFieldContainer}>
      <label className={styles.inputLabel}>Presentation File</label>
      <input
        type='file'
        accept='video/mp4,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/zip'
        id='fileInput'
        hidden
        onChange={handleFileChange}
        aria-describedby='file-upload-help file-upload-error'
      />
      <label className={styles.uploadPresentFile} htmlFor='fileInput'>
        {fileName}
      </label>
      <div id='file-upload-help' className={styles.uploadSizeInstruction}>
        Must be less than 100 MB & .mp4, .zip, .pptx, or .pdf file
      </div>
      {error && (
        <div
          id='file-upload-error'
          className={styles.fieldErrorMsg}
          role='alert'
        >
          {error}
        </div>
      )}
    </div>
  );
};

const InputField = ({ name, label, setFieldValue }) => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);

  const handleChange = (e, field) => {
    field.onChange(e);
    setUserDetails({ ...userDetails, [name]: e.target.value });
  };

  useEffect(() => {
    if (userDetails[name] && setFieldValue)
      setFieldValue(name, userDetails[name]);
  }, []);

  return (
    <Field name={name}>
      {({ field }) => (
        <div className={styles.inputFieldContainer}>
          <label className={styles.inputLabel}>{label}</label>
          <input
            {...field}
            className={styles.inputField}
            onChange={(e) => handleChange(e, field)}
          />
          <ErrorMessage
            className={styles.fieldErrorMsg}
            name={name}
            component='div'
          />
        </div>
      )}
    </Field>
  );
};

const InputFieldArray = ({
  name,
  label,
  handleKeyDown,
  inputVal,
  setInputVal,
  validationSchema,
  setFieldValue,
}) => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);

  const inputRef = useRef();

  /* Handlers */
  const handleChange = (e, name) => {
    setInputVal(e.target.value);
    localStorage.setItem(name, e.target.value);
  };

  const handleRemoveItem = (index, arrayHelpers, name) => {
    const { values } = arrayHelpers.form;

    const items = values[name];
    const filteredItems = items.filter((item) => item != items[index]);

    arrayHelpers.remove(index);
    setUserDetails({ ...userDetails, [name]: filteredItems });
  };

  useEffect(() => {
    if (userDetails[name] && setFieldValue)
      setFieldValue(name, userDetails[name]);
  }, []);

  return (
    <div className={styles.fieldArrayContainer}>
      <FieldArray name={name}>
        {(arrayHelpers) => {
          const { errors, touched, values } = arrayHelpers.form;
          const requiredFieldErr = errors[name]?.includes("at least one");

          /* Handlers */
          const handleKeyPress = (e) => {
            handleKeyDown(
              e,
              arrayHelpers,
              inputVal,
              setInputVal,
              name,
              validationSchema
            );
          };
          const handleFieldChange = (e) => handleChange(e, name);

          return (
            <div className={styles.inputFieldContainer}>
              <label className={styles.inputLabel}>{label}</label>
              <input
                ref={inputRef}
                className={styles.inputField}
                value={inputVal}
                onKeyDown={handleKeyPress}
                onChange={handleFieldChange}
              />

              <label className={styles.fieldErrorMsg}>
                {!(requiredFieldErr && !touched[name]) ? errors[name] : ""}
              </label>

              <ul className={styles.enteredItems}>
                {userDetails[name]?.map((item, index) => (
                  <li className={styles.enteredItem} key={index}>
                    {item}{" "}
                    <img
                      src='assets/2025/registration/removeItem.svg'
                      onClick={() =>
                        handleRemoveItem(index, arrayHelpers, name)
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
          );
        }}
      </FieldArray>
      <AddButton inputRef={inputRef} />
    </div>
  );
};

const FieldArrayEnterInstructions = ({ fieldName, items }) => {
  return (
    <ul className={styles.pressEnter}>
      <li>e.g. {items}</li>
      <li>Press enter, tab or space to add {fieldName}</li>
    </ul>
  );
};

/* Handlers */

const handleFinalSubmit = async (userDetails, setUserDetails) => {
  const finalObj = await handleSubmittedPitch(userDetails);
  console.log("final obj", finalObj);
  // submitProject(finalObj);
  submitPitch(finalObj)
    .then(() => {
      setUserDetails({
        ...userDetails,
        isPitchSubmitted: true,
        isSubmissionSucc: true,
      });
    })
    .catch((err) => {
      const errorMsgs = JSON.parse(err.response.data);
      const errorMsg = getErrorMsg(errorMsgs);
      setUserDetails({
        ...userDetails,
        isPitchSubmitted: true,
        isSubmissionSucc: false,
        failedSubmissionMsg: errorMsg,
      });
    });
};

/* Pitch form */
const PitchForm = () => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const [step, setStep] = useContext(StepContext);

  const [sectorInputVal, setSectorInputVal] = useState("");
  const [technologiesInputVal, setTechnologiesInputVal] = useState("");
  const [teamMembersInputVal, setTeamMembersInputVal] = useState("");

  const { region } = userDetails;

  return (
    <Formik
      initialValues={{
        ideaName: "",
        description: "",
        sector: [],
        technologies: [],
        teamMembers: [],
      }}
      validationSchema={PitchFormScheme}
      onSubmit={() => handleFinalSubmit(userDetails, setUserDetails)}
    >
      {({ values, setFieldError, setFieldValue }) => {
        const handleKeyDown = async (
          e,
          arrayHelpers,
          inputVal,
          setInputVal,
          name,
          schema
        ) => {
          const userPresses = ["Tab", "Enter", " "];
          if (userPresses.includes(e.key)) {
            e.preventDefault();
            const inputValue = inputVal.trim();
            const isTeamMembersField = name == "teamMembers";

            if (!inputValue) return;

            try {
              await schema.validateSync(inputValue);

              if (!values[name].includes(inputValue)) {
                const addItems = () => {
                  arrayHelpers.push(inputValue);
                  setUserDetails({
                    ...userDetails,
                    [name]: [...values[name], localStorage.getItem(name)],
                  });
                };

                const inputFieldLen = userDetails[name]?.length;

                if (isTeamMembersField && inputFieldLen == 2)
                  throw "You should enter 2 members max";

                if (
                  (isTeamMembersField && inputFieldLen < 2) ||
                  !isTeamMembersField
                ) {
                  addItems();
                }

                setInputVal("");

                setFieldError(name, undefined);

                localStorage.removeItem(name);
              }
            } catch (error) {
              setFieldError(name, error || error.message);
            }
          }
        };

        return (
          <Form className={styles.pitchFormContainer}>
            <InputField
              name='ideaName'
              label='Idea Name'
              setFieldValue={setFieldValue}
            />
            <InputField
              name='description'
              label='Description'
              setFieldValue={setFieldValue}
            />
            <InputFieldArray
              name='sector'
              label='Sector'
              setFieldValue={setFieldValue}
              handleKeyDown={handleKeyDown}
              inputVal={sectorInputVal}
              setInputVal={setSectorInputVal}
              validationSchema={Yup.string().min(2, "Too short")}
            />
            <FieldArrayEnterInstructions
              fieldName={" sector"}
              items={" Health Care, Education, ..."}
            />

            <InputFieldArray
              name='technologies'
              label='Technologies'
              setFieldValue={setFieldValue}
              handleKeyDown={handleKeyDown}
              inputVal={technologiesInputVal}
              setInputVal={setTechnologiesInputVal}
              validationSchema={Yup.string()}
            />
            <FieldArrayEnterInstructions
              fieldName={" technology"}
              items={" Java, Python, etc.."}
            />

            <UploadPresentationFile />
            <div className={styles.uploadSizeInstruction}>
              Must be less than 100 MB & .mp4, .zip, or .pdf file
            </div>

            <InputFieldArray
              name='teamMembers'
              label='Team Members'
              setFieldValue={setFieldValue}
              handleKeyDown={handleKeyDown}
              inputVal={teamMembersInputVal}
              setInputVal={setTeamMembersInputVal}
              validationSchema={Yup.string()
                .email("Invalid email format")
                .matches(
                  "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
                  "Please enter a valid email"
                )}
            />

            <div className={styles.btnsGp}>
              <SubmitButton text='Share Your Idea' />
              <GoBackButton
                text={"Back"}
                step={step}
                setStep={setStep}
                region={region}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PitchForm;
