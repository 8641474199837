import { useEffect, useState } from "react";
import styles from "./SessionAgenda.module.css";
import { getEventsByDate } from "../../APIs/apis";
import { getAssetPath, PATHS } from "../../config/paths";
import { UI_CONSTANTS } from "../../config/constants";

const AgendaItem = ({ key, item }) => {
  const formattedDate = new Date(item.startDateTime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <li key={key} className={styles["agenda-item"]}>
      <span className={styles["time"]}>{formattedDate}</span>
      <div className={styles["speaker-div"]}>
        <span className={styles["title"]}>{item.title}</span>
      </div>
    </li>
  );
};

const DropdownItem = ({ key, item, getDateAgenda }) => {
  const formattedDate = new Date(item.date)
    .toLocaleString("default", UI_CONSTANTS.DATE_FORMAT_SHORT)
    .toUpperCase();

  return (
    <li
      key={key}
      className={styles["dropdown-item"]}
      onClick={() => getDateAgenda(item.date)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          getDateAgenda(item.date);
        }
      }}
      tabIndex={0}
      role="button"
      aria-label={`Select agenda for ${formattedDate}`}
    >
      {formattedDate}
    </li>
  );
};

const DropdownSelectedDate = ({ selectedDate }) => {
  return (
    <span
      className={styles["date-in-agenda"]}
      type="button"
      data-toggle="dropdown"
      tabIndex={0}
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {selectedDate.toUpperCase()}
      <img
        className={styles["arrow-down"]}
        src={getAssetPath(PATHS.ASSETS.ICONS.ARROW_DOWN)}
        alt="Down arrow for collapsing list"
      />
    </span>
  );
};

const AgendaHeader = () => {
  return (
    <div className={styles["agenda-header"]}>
      <img
        src={getAssetPath(PATHS.ASSETS.ICONS.AGENDA_ICON)}
        width={30}
        height={30}
        alt="VOIS Hackathon agenda icon"
      />
      <span className={styles["agenda-title"]}>Agenda</span>
    </div>
  );
};

const AgendaListHeader = ({ dates, selectedDate, getDateAgenda }) => {
  return (
    <div className={styles["agenda-list-header"]}>
      <AgendaHeader />
      <DropdownSelectedDate selectedDate={selectedDate} />
      <div className={`dropdown-menu ${styles["dropdown-menu"]}`}>
        {dates.map((item, key) => (
          <DropdownItem key={key} item={item} getDateAgenda={getDateAgenda} />
        ))}
      </div>
    </div>
  );
};

const AgendaList = ({ DateAgenda }) => {
  return (
    <ul className={styles["agenda-list"]}>
      {DateAgenda.map((item, key) => (
        <AgendaItem key={key} item={item} />
      ))}
    </ul>
  );
};

const SessionAgenda = ({ dates, showAgendaList, setShowAgendaList }) => {
  const [spreadAgeda, setSpreadAgeda] = useState(false);
  const [selectedDate, setselectedDate] = useState("");
  const [DateAgenda, setDateAgenda] = useState([]);

  const getAgendaByDate = (date) =>
    getEventsByDate(date)
    .then((res) => {
      setDateAgenda(res.data);
      if (res.data.length > 0) {
        let date = new Date(res.data[0].startDate).toLocaleString("default", UI_CONSTANTS.DATE_FORMAT_SHORT);
        setselectedDate(date);
      }
    });

  useEffect(() => {
    if (!showAgendaList) {
      setTimeout(() => {
        setSpreadAgeda(true);
      }, 100);

      if (dates.length > 0) getAgendaByDate(dates[0].date);
    } else setSpreadAgeda(false);
  }, [showAgendaList]);

  return (
    <div
      className={`collapse ${showAgendaList ? "hidden" : "show"} ${
        spreadAgeda ? "" : styles["spread"]
      } ${styles["slido-container"]} `}
      data-parent="#accordion"
      onMouseLeave={() => setShowAgendaList(true)}
    >
      <AgendaListHeader
        dates={dates}
        selectedDate={selectedDate}
        getDateAgenda={getAgendaByDate}
      />
      <AgendaList DateAgenda={DateAgenda} />
    </div>
  );
};

export default SessionAgenda;
