import styles from "./Footer.module.css";
import { SocialMediaIcon } from "../_shared/components/icons";
import { NavLink } from "react-router-dom";

const footerLinks = ["FAQ", "Guidelines", "Careers"];
const footerSocialMedia = [
  { name: "linkedin", url: "https://www.linkedin.com/company/vois/" },
  { name: "instagram", url: "https://www.instagram.com/voisglobal/" },
  { name: "fb", url: "https://www.facebook.com/voisglobal/" },
];

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLinks}>
        {footerLinks.map((link) => (
          <NavLink className="link">{link}</NavLink>
        ))}
      </div>

      <div className={styles.socialMediaLinks}>
        {footerSocialMedia.map((SMLink) => (
          <a className="link" href={`${SMLink.url}`}>
            <SocialMediaIcon platformImg={SMLink.name} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Footer;