import { useState, useEffect, useMemo } from "react";
import { getAllAgendaItems } from "./APIs/apis";
import { createUtcDate } from "./helpers/helpers";

import styles from "./agenda.module.css";
import AgendaNavBar from "./components/AgendaNavBar/AgendaNavBar";
import AgendaFooter from "./components/AgendaFooter/AgendaFooter";

import AgendaSpeakerItem from "./components/AgendaContent/AgendaSpeaker/AgendaSpeakerItem/AgendaSpeakerItem";
import CoachingSessionsSchedule from "./components/AgendaContent/AgendaDates/CoachingSchedule/CoachingSessionsSchedule";
import AgendaDateItem from "./components/AgendaContent/AgendaDates/AgendaDateItem/AgendaDateItem";

import { Icon } from "./components/imgsAndSVGs/ImgsAndSVGs";

const AgendaDiv = () => {
  return (
    <div className={styles["agenda-header"]}>
      <Icon imgName={"agenda_icon.svg"} width={25} height={25} />
      {"Agenda"}
    </div>
  );
};

/* Agenda page */
function Agenda() {
  const [dates, setDates] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ date: "", arr: [] });
  const [coachSessionStart, setCoachSessionStart] = useState(new Date());
  const [coachSessionEnd, setCoachSessionEnd] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use useMemo to avoid unnecessary re-sorting on every render
  const sortedSelectedItem = useMemo(() => {
    return selectedItem?.arr.sort(
      (a, b) =>
        new Date(a.startDateTime).getUTCHours() -
        new Date(b.startDateTime).getUTCHours()
    );
  }, [selectedItem]);

  useEffect(() => {
    // Enhanced API call with loading and error states
    getAllAgendaItems(setDates, setSelectedItem, setLoading, setError);

    // Set Coach Session start & end times.
    setCoachSessionStart(createUtcDate("10:00:00"));
    setCoachSessionEnd(createUtcDate("13:00:00"));
  }, []);

  const openCoachesAgenda = async () => {
    window.open("/assets/Coaching-Agenda.pdf", "_blank");
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  if (loading) {
    return (
      <div className={styles["loading-container"]} aria-live='polite'>
        Loading agenda...
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={styles["error-container"]}
        aria-live='assertive'
        role='alert'
      >
        {error}
      </div>
    );
  }

  return (
    <div className={styles["main-agenda-bg"]}>
      <AgendaNavBar />
      <AgendaDiv />
      <div className={styles["main-div-container"]}>
        <div
          className={styles["dates"]}
          role='navigation'
          aria-label='Agenda dates'
        >
          <ul className={styles["dates-list"]}>
            {dates.map((item, i) => {
              return (
                <AgendaDateItem
                  key={i}
                  index={i}
                  item={item}
                  onItemClick={handleItemClick}
                  isSelected={selectedItem === item}
                  isLast={i === dates.length - 1}
                />
              );
            })}
          </ul>
          <CoachingSessionsSchedule
            startTime={coachSessionStart}
            endTime={coachSessionEnd}
            handleViewSchedule={openCoachesAgenda}
          />
        </div>
        <div className={styles["agenda"]} role='main' aria-label='Agenda items'>
          <ul className={styles["agenda-list"]}>
            {sortedSelectedItem?.map((item, index) => (
              <AgendaSpeakerItem key={index} speakerItem={item} />
            ))}
          </ul>
        </div>
      </div>
      <AgendaFooter />
    </div>
  );
}

export default Agenda;
