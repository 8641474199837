import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';
import Hero from './HeroSection/Hero';
import Prize from './PrizeSection/Prize';
import Judges from './JudgesSection/Judges';
import Timeline from './TimelineSection/Timeline';
import Join from './JoinSection/Join';
import Process from './ProcessSection/Process';
import Theme from './ThemeSection/Theme';

import "./Home.css"

const Home = () => {
    return (
        <div className="home">
            <NavBar />

            <Hero />
            <Prize />
            <Judges/>
            <Timeline />
            <Join />
            <Process />
            <Theme />
            
            <Footer /> 
        </div>
    );
};

export default Home;