import React, { lazy, Suspense, useEffect } from "react";
import styles from "./VideoScreen.module.css";

// Lazy load components for better performance
const VSNavBar = lazy(() => import("./VSNavBar/VSNavBar"));
const VSContent = lazy(() => import("./VSContent/VSContent"));
const VSFooter = lazy(() => import("./VSFooter/VSFooter"));

/**
 * Loading component for Suspense fallback
 * @returns {JSX.Element} - Rendered loading component
 */
const Loading = () => (
  <div className={styles["loading-container"]}>
    <div className={styles["loading-spinner"]}></div>
    <p>Loading...</p>
  </div>
);

/**
 * VideoScreen - Main container component for video display
 * 
 * @param {string} videoName - The name/title of the video to display
 * @param {string} videoSrc - URL or path to the video source
 * @returns {JSX.Element} - Rendered VideoScreen component
 */
function VideoScreen({ videoName, videoSrc }) {
  useEffect(() => {
    localStorage.setItem("firstTimeWithLoading", "true");
  }, []);

  return (
    <div className={styles["fullScreenBackground"]}>
      <Suspense fallback={<Loading />}>
        <VSNavBar />
        <VSContent videoName={videoName} videoSrc={videoSrc} />
        <VSFooter />
      </Suspense>
    </div>
  );
}

export default VideoScreen;