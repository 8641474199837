import { formatTime } from "../../../../helpers/helpers";
import styles from "./AgendaSpeakerItem.module.css"

const AgendaSpeakerItem = ({ speakerItem }) => {
  const dateObject = new Date(speakerItem.startDateTime);
  return (
    <li className={styles["agenda-item"]}>
      <span className={styles["time"]}>{formatTime(dateObject, false)}</span>
      <div className={styles["speaker-div"]}>
        <span className={styles["title"]}>{speakerItem.title}</span>
        <span className={styles["speaker"]}>{speakerItem.host}</span>
        <span className={styles["speaker"]}>{speakerItem.hostTitle}</span>
      </div>
    </li>
  );
};

export default AgendaSpeakerItem;