import styles from "./components.module.css";

const GetStartedFormStatement = () => {
  return (
    <>
      Welcome to{" "}
      <span className={styles.statementSpan}>VOIS Global Hackathon</span>, We
      are glade to have you onboard. First, Select your country to proceed:
    </>
  );
};

const CreateAccountFormStatement = () => {
  return (
    <>
      As a step to showcase your creative idea, Kindly fill this form to create
      an account on our <span className={styles.statementSpan}>Hackathon</span>.
    </>
  );
};

const ConsentFormStatement = () => {
  return (
    <>
      As a step to showcase your creative idea, Kindly accept this privacy and
      data use consent.
    </>
  );
};

const PitchFormStatement = () => {
  return <>It’s the time to share your creative idea details with us!</>;
};

export {
  GetStartedFormStatement,
  CreateAccountFormStatement,
  ConsentFormStatement,
  PitchFormStatement,
};
