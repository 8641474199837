import { formatTime } from "../../../../helpers/helpers";
import styles from "./CoachingSessionsSchedule.module.css"

const CoachingSessionsSchedule = ({
  startTime,
  endTime,
  handleViewSchedule,
}) => {
  return (
    <div className={styles["coaches-box"]}>
      <span className={styles["coaching-sessions-title"]}>
        Coaching sessions
      </span>
      <span className={styles["coaching-sessions-dates"]}>
        {formatTime(startTime, true)}
        {" - "}
        {formatTime(endTime, true)}
      </span>
      <span
        className={styles["coaching-sessions-btn"]}
        onClick={handleViewSchedule}
      >
        View coaching schedule
      </span>
    </div>
  );
};

export default CoachingSessionsSchedule;