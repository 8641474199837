import { useEffect, useState } from "react";
import styles from "./Imagery.module.css";

const ImageryComponent = ({ imageName }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [imageName]);

  return (
    <div
      className={`${styles.imageryContainer} ${styles.fadeImage} ${
        loaded ? styles.loaded : ""
      }`}
    >
      <img
        src={`assets/2025/registration/${imageName}.png`}
        height={window.innerHeight - 20}
        alt=""
        onLoad={() => setLoaded(true)}
      />

      <div className={styles.floatingBox}>
        <img
          src="assets/2025/registration/VOISLogoWhite.svg"
          width={100}
          alt=""
        />
        <img
          src="assets/2025/registration/HackathonLogo.svg"
          width={285}
          alt=""
        />
      </div>
    </div>
  );
};

const Imagery = ({ imageName }) => {
  return <ImageryComponent key={imageName} imageName={imageName} />;
};

export default Imagery;
