import { PATHS, getHomeAssetPath } from "../config/paths";

export const Ribbon = ({ additionalStyling, width }) => {
  return (
    <img
      className="ribbon"
      src={getHomeAssetPath(PATHS.UI.RIBBON)}
      style={additionalStyling}
      width={width}
      alt="Decorative ribbon"
    />
  );
};

export const SmallRibbon = ({ additionalStyling, width }) => {
  return (
    <img
      className="smallRibbon"
      src={getHomeAssetPath(PATHS.UI.RIBBON_MOBILE)}
      style={additionalStyling}
      width={width}
      alt="Decorative ribbon for mobile"
    />
  );
};

export const BlackDiamond = () => {
  return (
    <img
      src={getHomeAssetPath(PATHS.UI.BLACK_DIAMOND)}
      width={8}
      height={8}
      alt="Black diamond decorative element"
    />
  );
};

export const WhiteDiamond = () => {
  return (
    <img 
      className="whiteDiamond" 
      src={getHomeAssetPath(PATHS.UI.WHITE_DIAMOND)} 
      alt="White diamond decorative element" 
    />
  );
};

export const AbsDiamond = ({ marginTop, marginLeft }) => {
  return (
    <img
      src={getHomeAssetPath(PATHS.UI.WHITE_DIAMOND)}
      width={16}
      height={16}
      style={{
        position: "absolute",
        marginTop,
        marginLeft,
        zIndex: 1,
      }}
      alt="Positioned diamond decorative element"
    />
  );
};
