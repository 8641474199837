import { NavLink } from "react-router-dom";
import { SocialMediaIcon } from "../imgsAndSVGs/ImgsAndSVGs";
import { openLink } from "../../helpers/helpers";

import styles from "./SessionFooter.module.css";
import { PATHS } from "../../config/paths";

const FooterNavLink = ({ name, to }) => {
  return (
    <NavLink className={styles["left-section-content"]} to={to}>
      {name}
    </NavLink>
  );
};

const SocialMediaLink = ({ styles, platformName, link }) => {
  const platformFullName = platformName === 'LI' ? 'LinkedIn' : 
                          platformName === 'FB' ? 'Facebook' : 'Instagram';
  
  return (
    <div 
      className={styles["social-media-container"]}
      onClick={() => openLink(link)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openLink(link);
        }
      }}
      tabIndex={0}
      role="button"
      aria-label={`Visit VOIS ${platformFullName} page`}
    >
      <SocialMediaIcon
        styles={styles}
        platformName={platformName}
        link={link}
      />
    </div>
  );
};

const SessionFooter = () => {
  return (
    <div className={styles["footer-main-div"]}>
      <div className={styles["footer-content"]}>
        <div className={styles["left-section"]}>
          <FooterNavLink to="/faq" name={"FAQs"} />
          <FooterNavLink to="/guidelines" name={"Guidelines"} />
          <span
            className={styles["left-section-content"]}
            onClick={() => openLink(PATHS.EXTERNAL_LINKS.VOIS_CAREERS)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openLink(PATHS.EXTERNAL_LINKS.VOIS_CAREERS);
              }
            }}
            tabIndex={0}
            role="button"
            aria-label="Visit VOIS Careers page"
          >
            Careers
          </span>
        </div>
        <div className={styles["right-section"]}>
          <SocialMediaLink
            styles={styles}
            platformName={"LI"}
            link={PATHS.EXTERNAL_LINKS.VOIS_LINKEDIN}
          />
          <SocialMediaLink
            styles={styles}
            platformName={"IG"}
            link={PATHS.EXTERNAL_LINKS.VOIS_INSTAGRAM}
          />
          <SocialMediaLink
            styles={styles}
            platformName={"FB"}
            link={PATHS.EXTERNAL_LINKS.VOIS_FACEBOOK}
          />
        </div>
      </div>
    </div>
  );
};

export default SessionFooter;
