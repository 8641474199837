import styles from "./Judges.module.css";

import { BlackDiamond } from "../_shared/components/shapes";

const JudgePersonalInfo = ({
  judgeName,
  judgeTitle,
  judgeImg,
}) => {
  return (
    <div
      className={styles.judge}
      style={{
        backgroundImage: `url('assets/2025/home/${judgeImg}`,
      }}
    >
      <div className={styles.judgeInfo}>
        <div className={styles.judgeName}>
          <BlackDiamond />
          <div>{judgeName}</div>
          <BlackDiamond />
        </div>
        <div className={styles.judgeTitle}>{judgeTitle}</div>
      </div>
    </div>
  );
};

const judgesPersonalInfo = [
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
  {
    judgeName: "John Doe",
    judgeTitle: "CEO at Microsoft",
    judgeImg: "judge.png",
  },
];

const Judges = () => {
  return (
    <div className={styles.judgesContainer}>
      <h1 className="gradientHeader">Your hackathon judges</h1>
      <div className={styles.HackathonJudges}>
        {judgesPersonalInfo.map((judge) => (
          <JudgePersonalInfo
            judgeName={judge.judgeName}
            judgeTitle={judge.judgeTitle}
            judgeImg={judge.judgeImg}
          />
        ))}
      </div>
    </div>
  );
};

export default Judges;
