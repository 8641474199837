import axios from "axios";

/**
 * Fetches all agenda items from the server
 * @param {Function} setDates - State setter for dates
 * @param {Function} setSelectedItem - State setter for selected item
 * @param {Function} setLoading - Optional state setter for loading state
 * @param {Function} setError - Optional state setter for error state
 */
const getAllAgendaItems = (setDates, setSelectedItem, setLoading, setError) => {
  if (setLoading) setLoading(true);

  axios
    .get(`${process.env.REACT_APP_SERVER_URL}newagenda/getall`)
    .then((r) => {
      const sortedData = r.data.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      setDates(sortedData);
      setSelectedItem(
        sortedData.length > 0 ? sortedData[0] : { date: "", arr: [] }
      );
      if (setLoading) setLoading(false);
    })
    .catch((error) => {
      console.error("Failed to fetch agenda items:", error);
      if (setError)
        setError("Failed to load agenda data. Please try again later.");
      if (setLoading) setLoading(false);
      // Set default empty values to prevent UI errors
      setDates([]);
      setSelectedItem({ date: "", arr: [] });
    });
};

export { getAllAgendaItems };
