import React, { useState, useEffect } from "react";

const MainUserContext = React.createContext([() => {}]);

const MainUserProvider = ({ children }) => {
  // Try to use the debug state if it exists
  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    uploadCv: "",
    password: "",
    passwordConfirm: "",
    region: "",
    CV: "",
    isAdmin: false,
    birthDate: "",
    teamMembers: [],
    participatedBefore: false,
    sameIdea: false,
    won: false,
    place: "",
    hackathonName: "",
    ideaPreviousName: "",
    termsAccepted: false,
    privacyAccepted: false,
  };

  const [userDetails, setUserDetails] = useState(initialState);

  const changeDetails = (data) => {
    console.log("changeDetails called with:", data);
    setUserDetails(data);
  };

  return (
    <MainUserContext.Provider value={[userDetails, changeDetails]}>
      {children}
    </MainUserContext.Provider>
  );
};

export { MainUserContext, MainUserProvider };
