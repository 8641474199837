import axios from "axios";

const getAllAgendaItems = async() => {
  try {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}newagenda/getall`);
  } catch (error) {
    console.error("Error fetching agenda items:", error);
    throw error;
  }
};

const getEventsByDate = async (date) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_SERVER_URL}newagenda/geteventsbydate`, {
        date: date,
      });
  } catch (error) {
    console.error("Error fetching events by date:", error);
    throw error;
  }
};

const getSessionByRegion = async () => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_SERVER_URL}session/getSessionByRegion/all`);
  } catch (error) {
    console.error("Error fetching session by region:", error);
    throw error;
  }
};

export { getAllAgendaItems, getEventsByDate, getSessionByRegion };
