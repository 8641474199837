import styles from "./AgendaDateItem.module.css"
import { Icon } from "../../../imgsAndSVGs/ImgsAndSVGs";


const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AgendaDateItem = ({
  key,
  index,
  item,
  onItemClick,
  isSelected,
  isLast,
}) => {
  const dateObject = new Date(item.date);
  const weekday = dateObject.getDay();

  const getSelectedStyles = (name) => {
    return isSelected ? styles["selected"] + " " + styles[name] : styles[name];
  };

  const getSelectedChevron = () => {
    return isSelected ? (
      <Icon imgName={"white_chevron.svg"} width={27} height={27} />
    ) : (
      <Icon imgName={"black_chevron.svg"} width={27} height={27} />
    );
  };

  // Get the weekday name
  const weekdayName = weekdays[weekday];
  return (
    <li
      key={key}
      className={getSelectedStyles("date-item")}
      onClick={() => onItemClick(item)}
    >
      <span className={getSelectedStyles("day")}>Day {index + 1}</span>
      <span className={getSelectedStyles("weekday")}>
        {weekdayName}
        {getSelectedChevron()}
      </span>
      <span className={getSelectedStyles("weekdate")}>
        {dateObject.toLocaleDateString("en-GB")}
      </span>
      {isLast || isSelected ? null : <div className={styles["red-line"]}></div>}
    </li>
  );
};

export default AgendaDateItem;