import styles from "./NavBar.module.css";
import { Ribbon } from "../_shared/components/shapes";
import { useWindowWidth } from "../_shared/hooks/hooks";
import { HamMenu } from "../_shared/components/icons";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { getHomeAssetPath, PATHS } from "../_shared/config/paths";

const navLinks = [
  { name: "HOME", link: "/" },
  { name: "GUIDELINES", link: "/guidelines" },
  { name: "FAQ", link: "/faq" },
  { name: "TIMELINE", link: "#timeline-section", isScroll: true },
  { name: "LOGIN", link: "/login" },
];

const NavBar = () => {
  const windowWidth = useWindowWidth();
  const initialMenuToggleState = !isMobile;

  const [showNavLinksDD, setShowNavLinksDD] = useState(initialMenuToggleState);

  const handleNavLinkClick = (e, link) => {
    if (link.isScroll) {
      e.preventDefault();
      const element = document.getElementById(link.link.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <nav className={styles.navBarContainer}>
      <div className={styles.navBar}>
        <div className={styles.VOISLogo}>
          <img src={getHomeAssetPath(PATHS.UI.VOIS_LOGO)} alt='VOIS Logo' />
        </div>
        {showNavLinksDD && (
          <div className={styles.navLinksContainer}>
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                className={`link ${styles.navLink}`}
                to={link.link}
                onClick={(e) => handleNavLinkClick(e, link)}
              >
                {link.name}
              </NavLink>
            ))}
          </div>
        )}
        <HamMenu toggle={showNavLinksDD} setToggle={setShowNavLinksDD} />
      </div>
      <Ribbon additionalStyling={{ marginTop: -10 }} width={windowWidth} />
    </nav>
  );
};

export default NavBar;
