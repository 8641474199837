import styles from "./CountdownRenderer.module.css"

const TimeUnitCounter = ({ timeUnitName, timeUnitCount }) => {
  return (
    <div className={styles["time-unit-container"]}>
      <div className={styles["countdown-number-title"]}>{timeUnitCount}</div>
      <span className={styles["countdown-number-sub"]}>{timeUnitName}</span>
    </div>
  );
};

const Colon = ({}) => {
  return (
    <div className={styles["timer-colon"]}>
      <div className={styles["colon-circle"]}></div>
      <div className={styles["colon-circle"]}></div>
    </div>
  );
};

const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  setIsBeforeSession,
}) => {
  if (completed) {
    setIsBeforeSession(false);
    return null;
  }
  return (
    <div className={styles["countdown-renderder-container"]}>
      <TimeUnitCounter timeUnitName={"Days"} timeUnitCount={days}/>
      <Colon />
      <TimeUnitCounter timeUnitName={"Hours"} timeUnitCount={hours}/>
      <Colon />
      <TimeUnitCounter timeUnitName={"Minutes"} timeUnitCount={minutes}/>
      <Colon />
      <TimeUnitCounter timeUnitName={"Seconds"} timeUnitCount={seconds}/>
    </div>
  );
};

export default CountdownRenderer;
