import { useEffect, useRef, useState } from "react";

import { CircularProgress } from "@mui/material";
import { getAllAgendaItems, getSessionByRegion } from "./APIs/apis";

import SessionNavBar from "./components/SessionNavBar/SessionNavBar";
import CountdownRenderer from "./components/CountdownRenderer/CountdownRenderer";
import BeforeSession from "./components/BeforeSession/BeforeSession";
import FinishedSession from "./components/FinishedSession/FinishedSession";
import SessionAgenda from "./components/SessionAgenda/SessionAgenda";
import SessionFooter from "./components/SessionFooter/SessionFooter";

import styles from "./Session.module.css";
import { checkLogInTime } from "./helpers/helpers";
import { getAssetPath, PATHS } from "./config/paths";
import { UI_CONSTANTS } from "./config/constants";

const ShowAgendaBtn = ({ showBtn, handleshowAgendaList }) => {
  return (
    showBtn && (
      <button
        className={styles["show-agenda-btn"]}
        data-toggle="collapse"
        onMouseEnter={() => handleshowAgendaList(false)}
      >
        <img src={getAssetPath(PATHS.ASSETS.ICONS.AGENDA_ICON)} alt="VOIS Hackathon agenda icon" />
      </button>
    )
  );
};

const StreamLoading = () => {
  return (
    <div className={styles["stream-loading-progress"]}>
      <CircularProgress style={{ color: UI_CONSTANTS.LOADING_SPINNER_COLOR }} />
      <h1>Stream Loading...</h1>
    </div>
  );
};

const LiveStreamer = ({ streamSrc }) => {
  const [streamLoading, setStreamLoading] = useState(true);
  return (
    <div
      className={styles["live-streamer-container"]}
      style={{ justifyContent: streamLoading ? UI_CONSTANTS.JUSTIFY_CENTER : UI_CONSTANTS.JUSTIFY_FLEX_START }}
    >
      {streamLoading && <StreamLoading />}

      <iframe
        style={{ display: streamLoading ? UI_CONSTANTS.DISPLAY_NONE : UI_CONSTANTS.DISPLAY_BLOCK }}
        className={styles["live-streamer"]}
        src={streamSrc}
        height={UI_CONSTANTS.IFRAME_HEIGHT}
        frameBorder={UI_CONSTANTS.IFRAME_FRAME_BORDER}
        scrolling={UI_CONSTANTS.IFRAME_SCROLLING}
        allow={UI_CONSTANTS.IFRAME_ALLOW}
        onLoad={() => setStreamLoading(false)}
      />
    </div>
  );
};

function Session() {
  const logInDate = new Date();

  const [isBeforeSession, setIsBeforeSession] = useState(false);
  const [isAfterSession, setIsAfterSession] = useState(false);

  const [showAgendaList, setShowAgendaList] = useState(true);
  const [agenda, setAgenda] = useState();
  const [loading, setloading] = useState(true);
  const [dates, setDates] = useState([]);

  const isFirstRun = useRef(true);

  const CDRenderer = ({ days, hours, minutes, seconds, completed }) => (
    <CountdownRenderer
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      completed={completed}
      setIsBeforeSession={setIsBeforeSession}
    />
  );

  /* Handlers */
  const handleshowAgendaList = (showAgendaList) => {
    setShowAgendaList(showAgendaList);
  };

  const handleCountdownCompletion = () => {
    setIsBeforeSession(false);
  };

  /* Hooks */
  useEffect(() => {
    if (isFirstRun.current) {
      getSessionByRegion().then((res) => setAgenda(res.data));
      isFirstRun.current = false;
    }
    checkLogInTime(
      logInDate,
      agenda,
      setIsBeforeSession,
      setIsAfterSession,
      setloading
    );
    return;
  }, [agenda, logInDate]);

  useEffect(() => {
    getAllAgendaItems().then((r) => {
      setDates(r.data.sort((a, b) => new Date(a.date) - new Date(b.date)));
    });
  }, []);

  
  /* Session screen */
  if (isBeforeSession)
    return (
      <BeforeSession
        date={agenda[0].startDate}
        renderer={CDRenderer}
        handleCountdownCompletion={handleCountdownCompletion}
      />
    );

  if (isAfterSession) return <FinishedSession />;

  return (
    <div className={styles["session-container"]}>
      <SessionNavBar />
      <div className={styles["session-content"]}>
        <ShowAgendaBtn
          showBtn={showAgendaList}
          handleshowAgendaList={handleshowAgendaList}
        />
        {!loading && (
          <>
            <SessionAgenda
              dates={dates}
              showAgendaList={showAgendaList}
              setShowAgendaList={setShowAgendaList}
            />
            <LiveStreamer streamSrc={agenda[0].sessionLink} />
          </>
        )}
      </div>
      <SessionFooter />
    </div>
  );
}

export default Session;
