/**
 * UI Constants for the Session component
 * Centralizing these values makes the code more maintainable
 */

export const UI_CONSTANTS = {
  // Layout
  SESSION_MIN_HEIGHT: '88vh',
  IFRAME_HEIGHT: '475',
  
  // Colors
  LOADING_SPINNER_COLOR: '#e60000',
  
  // Display values
  DISPLAY_BLOCK: 'block',
  DISPLAY_NONE: 'none',
  
  // Flex alignment
  JUSTIFY_CENTER: 'center',
  JUSTIFY_FLEX_START: 'flex-start',
  
  // iframe attributes
  IFRAME_ALLOW: 'camera; microphone; fullscreen',
  IFRAME_FRAME_BORDER: '0',
  IFRAME_SCROLLING: 'no',
  
  // Date formatting
  DATE_FORMAT: 'ddd, MMMM Do, YYYY [at] h:mm A',
  DATE_FORMAT_SHORT: {
    day: '2-digit',
    month: 'short',
  }
};
