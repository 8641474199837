import React, { useEffect, useState, useContext } from "react";
import "./guardianForm.css";
import * as Yup from "yup";
import { GuardianContext } from "../../../../contexts/guardianContext";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import { useFormik } from "formik";
import Stepper from "../stepper/stepper";
import moment from "moment";
const GuardianForm = () => {
  const [guardianDetails, changeDetails] = useContext(GuardianContext);
  const [Adult, setAdult] = React.useState(true);
  const [goNext, setGoNext] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const {
    touched,
    values,
    handleChange,
    errors,
    isValid,
    handleSubmit,
    handleBlur,
    getFieldProps,
  } = useFormik({
    validationSchema: Adult
      ? Yup.object({
          nameid: Yup.string()
            .min(5, "Minimum 5 characters")
            .max(50, "Maximum 50 characters")
            .matches(
              /[ء-ي]+/,
              "يرجى ادخال الاسم باللغة العربية كما هو موضح ببطاقة الرقم القومى"
            )
            .required("name is required"),
          address: Yup.string()
            .min(5, "Minimum 5 characters")
            .matches(/[ء-ي]+/, "يرجى ادخال العنوان باللغة العربية")
            .required("Address is required"),
          id: Yup.string()
            .min(14, "Must be 14 number id")
            .max(14, "Must be 14 number id")
            .required(" National ID is required")
            .matches(/^[0-9]+$/, "This is not a National ID number")
            .test(
              "validate National ID",
              "National ID is invalid",
              function (value) {
                return checkIdDate(values.id);
              }
            ),
          idIssuance: Yup.date()
            .required(" National ID Issuance is required")
            .test(
              "validate National ID",
              "National ID is expired",
              function (value) {
                let res = "";
                const [result, errorMsg] = dateDiffs(values.idIssuance);
                res =
                  result === true
                    ? true
                    : this.createError({
                        message: errorMsg,
                      });
                return res;
              }
            ),
        })
      : Yup.object({
          nameid: Yup.string()
            .min(5, "Minimum 5 characters")
            .max(50, "Maximum 50 characters")
            .matches(
              /[ء-ي]+/,
              "يرجى ادخال الاسم باللغة العربية كما هو موضح ببطاقة الرقم القومى"
            )
            .required("name is required"),
          address: Yup.string()
            .min(5, "Minimum 5 characters")
            .matches(/[ء-ي]+/, "يرجى ادخال العنوان باللغة العربية")
            .required("Address is required"),
          id: Yup.string()
            .min(14, "Must be 14 number id")
            .max(14, "Must be 14 number id")
            .required(" National ID is required")
            .matches(/^[0-9]+$/, "This is not a National ID number")
            .test(
              "validate National ID",
              "National ID is invalid",
              function (value) {
                return checkIdDate(values.id);
              }
            ),
          idIssuance: Yup.date()
            .required(" National ID Issuance is required")
            .test(
              "validate National ID",
              "National ID is expired",
              function (value) {
                let res = "";
                const [result, errorMsg] = dateDiffs(values.idIssuance);
                res =
                  result === true
                    ? true
                    : this.createError({
                        message: errorMsg,
                      });
                return res;
              }
            ),
          guardianName: Yup.string()
            .min(5, "Minimum 5 characters")
            .max(50, "Maximum 50 characters")
            .matches(
              /[ء-ي]+/,
              "يرجى ادخال الاسم باللغة العربية كما هو موضح ببطاقة الرقم القومى"
            )
            .required("Guardian name is required"),
          guardianAddress: Yup.string()
            .min(5, "Minimum 5 characters")
            .matches(/[ء-ي]+/, "يرجى ادخال العنوان باللغة العربية")
            .required("Guardian Address is required"),
          guardianNationalId: Yup.string()
            .min(14, "Must be 14 number")
            .max(14, "Must be 14 number")
            .required("Guardian National ID is required")
            .matches(/^[0-9]+$/, "This is not a National ID number")
            .test(
              "validate National ID",
              "National ID is invalid",
              function (value) {
                if (values.guardianNationalId !== undefined) {
                  return checkIdDate(values.guardianNationalId);
                }
              }
            ),
          guardianNationalIssuance: Yup.date()
            .required("Guardian National ID Issuance is required")
            .test(
              "validate National ID",
              "National ID is expired",
              function (value) {
                let res = "";
                const [result, errorMsg] = dateDiffs(
                  values.guardianNationalIssuance
                );
                res =
                  result === true
                    ? true
                    : this.createError({
                        message: errorMsg,
                      });
                return res;
              }
            ),
        }),
    initialValues: {
      guardianName: guardianDetails.guardianName,
      guardianAddress: guardianDetails.guardianAddress,
      guardianNationalId: guardianDetails.guardianNationalId,
      guardianNationalIssuance: guardianDetails.guardianNationalIssuance,
      nameid: guardianDetails.nameid,
      address: guardianDetails.address,
      id: guardianDetails.id,
      idIssuance: guardianDetails.idIssuance,
      isAdult: guardianDetails.isAdult,
    },
    onSubmit: () => changeDetails(values),
  });

  useEffect(() => {
    if (values.id != "") {
      setAdult(calculateAge(values));
      values.isAdult = calculateAge(values);
    }
  }, [values]);

  function dateDiffs(IssuanceDate) {
    let result;
    let errorMsg = "";
    if (moment().diff(moment(IssuanceDate), "years") > 7) {
      errorMsg = "National Id is expired";
      result = false;
    } else if (moment().diff(moment(IssuanceDate)) < 0) {
      errorMsg = `National Id can't be after today's date`;
      result = false;
    } else {
      result = true;
    }
    return [result, errorMsg];
  }

  const checkIdDate = (value) => {
    let result =
      value.substr(1, 2) && value.substr(3, 2) < 13 && value.substr(5, 2) < 32;
    return result;
  };

  const calculateAge = (values) => {
    const nationalIDYear =
      values.id.toString().charAt(1) == "0" ||
      values.id.toString().charAt(1) == "1" ||
      values.id.toString().charAt(1) == "2"
        ? "20" + values.id.toString().substring(1, 3)
        : "19" + values.id.toString().substring(1, 3);

    let age = moment(
      `${values.id.toString().substring(3, 5)}/${values.id
        .toString()
        .substring(5, 7)}/${nationalIDYear}`,
      "MM/DD/YYYY"
    ).from(moment());

    let candidateAge = age.charAt(0) + age.charAt(1);
    let res = parseInt(candidateAge) >= 21 ? true : false;
    return res;
  };

  return (
    <form
      onSubmit={handleSubmit}
      id='guardianform'
      className='row user-details-container'
    >
      <div className='col-md-3 mr-5 p-0'>
        {" "}
        <Stepper
          next={handleSubmit}
          NextStep={goNext}
          BackStep={goBack}
          disabled={!isValid}
        />
      </div>
      <div className='col-md-8 scrolled user-details-alignment'>
        {/* {Object.keys(errors).length === 0
          ? updateData(values)
          : handleGuardiandisabled(true)} */}
        <div className='title'>Contact Information</div>
        <div className='row'>
          <div className='form-group input-width-100 col-md-6 '>
            <label htmlFor='nameid' className=' label-format'>
              Full Name
              <span className='required'> *</span>
              <span className='required ml-1'> (required)</span>
              <span className='required ml-1'> (arabic)</span>
            </label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.nameid}
              type='text'
              className='text-box'
              id='nameid'
              name='nameid'
              {...getFieldProps("nameid")}
            />
            <span className='hint '>
              <li className=''>
                Must be as written in Arabic as your national ID.
              </li>
            </span>
            {touched.nameid && errors.nameid ? (
              <div className='alert alert-danger reg-alert'>
                {errors.nameid}
              </div>
            ) : null}
          </div>
          <div className='form-group input-width-100 col-md-6 '>
            <label htmlFor='address' className='address label-format'>
              Address<span className='required'>*</span>
              <span className='required ml-1'> (required)</span>
              <span className='required ml-1'> (arabic)</span>
            </label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              type='text'
              className='text-box'
              id='address'
              name='address'
              {...getFieldProps("address")}
            />
            {touched.address && errors.address ? (
              <div className='alert alert-danger reg-alert'>
                {errors.address}
              </div>
            ) : null}
            <span className='hint'>
              <li>Must be in Arabic.</li>
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='form-group input-width-100 col-md-6 '>
            <label htmlFor='id' className='national-id label-format'>
              National ID <span className='required'>*</span>
              <span className='required ml-1'> (required)</span>
            </label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
              type='text'
              className='text-box'
              id='id'
              name='id'
              {...getFieldProps("id")}
            />
            {touched.id && errors.id ? (
              <div className='alert alert-danger reg-alert'>{errors.id}</div>
            ) : null}
          </div>
          <div className='form-group input-width-100 col-md-6'>
            <label
              htmlFor='idIssuance'
              className='national-id-iss label-format'
            >
              National ID Issuance Date
              <span className='required'>*</span>
              <span className='required ml-1'> (required)</span>
            </label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.idIssuance}
              type='month'
              className='date-box'
              id='idIssuance'
              name='idIssuance'
              {...getFieldProps("idIssuance")}
            />
            {touched.idIssuance && errors.idIssuance ? (
              <div className='alert alert-danger reg-alert'>
                {errors.idIssuance}
              </div>
            ) : null}
          </div>
        </div>
        {Adult == false ? (
          <>
            {" "}
            <div className='row'>
              <div className='form-group input-width-100 col-md-6 '>
                <label
                  htmlFor='guardianName'
                  className='guard-name label-format'
                >
                  Guardian Name <span className='required'>*</span>
                  <span className='required ml-1'> (required)</span>
                  <span className='required ml-1'> (arabic)</span>
                </label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guardianName}
                  type='text'
                  className='text-box'
                  id='guardianName'
                  name='guardianName'
                  {...getFieldProps("guardianName")}
                />
                <span className='hint'>
                  <li>Must be in Arabic.</li>
                </span>
                {touched.guardianName && errors.guardianName ? (
                  <div className='alert alert-danger reg-alert'>
                    {errors.guardianName}
                  </div>
                ) : null}
              </div>
              <div className='form-group input-width-100 col-md-6'>
                <label htmlFor='address' className='guard-add label-format'>
                  Guardian Address<span className='required'>*</span>
                  <span className='required ml-1'> (required)</span>
                  <span className='required ml-1'> (arabic)</span>
                </label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guardianAddress}
                  type='text'
                  className='text-box'
                  id='guardianAddress'
                  name='guardianAddress'
                  {...getFieldProps("guardianAddress")}
                />
                {touched.guardianAddress && errors.guardianAddress ? (
                  <div className='alert alert-danger reg-alert'>
                    {errors.guardianAddress}
                  </div>
                ) : null}
                <span className='hint'>
                  <li>Must be in Arabic.</li>
                </span>
              </div>
            </div>
            <div className='row'>
              <div className='form-group input-width-100 col-md-6 '>
                <label
                  htmlFor='guardianNationalId'
                  className='guard-national-id label-format'
                >
                  Guardian National ID <span className='required'>*</span>
                  <span className='required ml-1'> (required)</span>
                </label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guardianNationalId}
                  type='text'
                  className='text-box'
                  id='guardianNationalId'
                  name='guardianNationalId'
                  {...getFieldProps("guardianNationalId")}
                />
                {touched.guardianNationalId && errors.guardianNationalId ? (
                  <div className='alert alert-danger reg-alert'>
                    {errors.guardianNationalId}
                  </div>
                ) : null}
              </div>
              <div className='form-group input-width-100 col-md-6'>
                <label
                  htmlFor='guardianNationalIssuance'
                  className='guard-national-id-iss label-format'
                >
                  Guardian National ID Issuance Date
                  <span className='required'>*</span>
                  <span className='required ml-1'> (required)</span>
                </label>
                <input
                  type='month'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guardianNationalIssuance}
                  className='date-box'
                  id='guardianNationalIssuance'
                  name='guardianNationalIssuance'
                  {...getFieldProps("guardianNationalIssuance")}
                />
                {touched.guardianNationalIssuance &&
                errors.guardianNationalIssuance ? (
                  <div className='alert alert-danger reg-alert'>
                    {errors.guardianNationalIssuance}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}{" "}
        <div className='btn-next-container'>
          <div
            className='back-btn-container'
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            style={{ visibility: "visible" }}
          >
            <img src='/assets/back-arrow.svg'></img>
            <button
              type='button'
              className='back-btn'
              onClick={() => setGoBack(true)}
            >
              BACK
            </button>
          </div>
          <button
            // type='submit'
            onClick={() => {
              setGoNext(true);
            }}
            type='button'
            disabled={
              !isValid ||
              (Object.keys(touched).length === 0 &&
                touched.constructor === Object)
            }
            className={
              !isValid ||
              (Object.keys(touched).length === 0 &&
                touched.constructor === Object)
                ? "next-btn-disabled"
                : "next-btn-egypt"
            }
          >
            NEXT
          </button>
        </div>
      </div>
    </form>
  );
};

export default GuardianForm;
