import { isMobile } from "react-device-detect";
import { WhiteDiamond, AbsDiamond } from "../_shared/components/shapes";
import styles from "./Process.module.css";

const ProcessWhiteVerLine = () => {
  return <div className={styles.verticalLine}></div>;
};

const Step = ({ order, stepHeader, stepDetails }) => {
  const processHeaderRectImg = isMobile
    ? "processHeaderRect_mob.svg"
    : "processHeaderRect.svg";

  const detailLRSpacing = !(order % 2) ? { left: 65 } : { right: 65 };
  return (
    <div className={styles.stepContainer}>
      <div className={styles.stepHeaderContainer}>
        <h4 className={styles.stepOrder}>STEP {order}</h4>

        <img
          className={isMobile ? styles.processRectMob : styles.processRect}
          src={`assets/2025/home/${processHeaderRectImg}`}
          alt=""
        />

        <div className={styles.stepHeader}>
          <WhiteDiamond  />
          <div>{stepHeader}</div>
          <WhiteDiamond  />
        </div>
      </div>

      <div className={styles.stepDetails} style={detailLRSpacing}>
        {stepDetails}
      </div>
    </div>
  );
};

const StayTuned = () => {
  return (
    <div>
      <AbsDiamond
        marginTop={!isMobile ? -55 : -45}
        marginLeft={!isMobile ? 115 : 86}
      />
      <AbsDiamond
        marginTop={!isMobile ? 285 : 219}
        marginLeft={!isMobile ? 115 : 86}
      />
      <AbsDiamond
        marginTop={!isMobile ? 115 : 86}
        marginLeft={!isMobile ? -55 : -45}
      />
      <AbsDiamond
        marginTop={!isMobile ? 115 : 86}
        marginLeft={!isMobile ? 285 : 219}
      />

      <div className={styles.stayTunedBlackDiamond}>
        <div style={{ rotate: "-45deg" }}>STAY TUNED</div>
      </div>
    </div>
  );
};

const Process = () => {
  return (
    <div className={styles.processContainer}>
      <h1 className={styles.processSecHeader}>Process</h1>
      <div className={styles.stepsContainer}>
        <Step
          order={1}
          stepHeader={"Create your team"}
          stepDetails={`You have the options to participate as an individual or create a team up to 3 members with different skill sets to develop your solution.​ We encourage you to create cross functional teams across different Service Lines.​If you need help to find team member(s) please usethis toolto see if anyone with an idea or technical skills are looking for team member(s) .`}
        />
        <ProcessWhiteVerLine />

        <Step
          order={2}
          stepHeader={"submit your pitch"}
          stepDetails={`Your pitch should show how your solution will contribute to the overall purpose and ambitions for VOIS. Choose from one of the following tracks - Customer, Simplicity or Growth. Explain how your solution will enable our digital future in the chosen area based on the existing Power Platform.`}
        />
        <ProcessWhiteVerLine />
      </div>
      <StayTuned />
      <div className={styles.stayTunedStatement}>
        The best 30 innovative, impactful, technically feasible solutions will
        be selected to join the competition. We will send a confirmation email
        about the chosen teams. Keep an eye out for this email to know whether
        you are among them.
      </div>
    </div>
  );
};

export default Process;
