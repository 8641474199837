import CreateAccountForm from "../CreateAccountForm/CreateAccountForm";

const RegionFormInfo = ({ region }) => {
  return region == "egypt" ? (
    <CreateAccountForm
      hasArabicFulName={true}
      hasNationalID={true}
      hasPhoneVer={true}
    />
  ) : region == "india" ? (
    <CreateAccountForm
      hasArabicFulName={false}
      hasBirthDate={true}
      hasNationalID={false}
      hasPhoneVer={false}
    />
  ) : region == "romania" ? (
    <CreateAccountForm
      hasArabicFulName={false}
      hasBirthDate={true}
      hasNationalID={false}
      hasPhoneVer={true}
    />
  ) : (
    <></>
  );
};

export default RegionFormInfo;
