import React from "react";
import HiddenGrouped from "./GroupedHidden";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function Grouped({ data, final, panelID }) {
  const [criterias, setCriterias] = useState([]);
  const [Loading, setLoading] = useState(false);
  const getfiltrationPanelCriterias = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/criteria`, {
        id: panelID,
      })
      .then((res) => {
        console.log(res.data);
        res.data.map((l) => {
          let x = criterias;
          x.push(l.name);
          setCriterias(x);
          criterias.sort();
        });
        setLoading(true);
      });
  };
  useEffect(() => {
    getfiltrationPanelCriterias();
    console.log("cc", criterias);
  }, []);
  function compare(a, b) {
    var nameA = a.criteriaName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.criteriaName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  // let criterias = Object.keys(data).sort((a, b) => {
  //   var nameA = a.toUpperCase(); // ignore upper and lowercase
  //   var nameB = b.toUpperCase(); // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // });

  return (
    <div className=' super-admin-container'>
      <HiddenGrouped data={data} final={final} panelID={panelID} />
      {Loading ? (
        <table className='table table-fixed-super'>
          <tbody style={{ width: "100%" }}>
            <tr
              style={{
                display: "flex",
                position: "sticky",
                top: "0",
                zIndex: "999",
                backgroundColor: "white",
              }}
            >
              {criterias.map((item, index) => {
                return (
                  <td
                    className='col-2'
                    style={{ backgroundColor: "white", padding: "20px" }}
                    key={index}
                  >
                    {item}
                  </td>
                );
              })}
            </tr>
            {final.map(function (row, i) {
              let temp = row.sort(compare);

              return (
                <tr key={i} style={{ display: "flex" }}>
                  {temp.map(function (cell) {
                    if (
                      criterias.filter((r) => r == cell.criteriaName).length > 0
                    ) {
                      return (
                        <td className='col-2' key={i}>
                          <div>
                            <span>Project {cell.projectName}</span>
                            <div>
                              <span>{cell.value}</span>
                            </div>
                          </div>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </div>
  );
}
export default Grouped;
