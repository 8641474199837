/**
 * Configuration file for asset paths
 * This centralizes all path definitions to make maintenance easier
 */

export const PATHS = {
  // Base paths
  HOME_ASSETS: "assets/2025/home/",
  
  // Social media icons
  SOCIAL: {
    FACEBOOK: "fb",
    INSTAGRAM: "instagram",
    LINKEDIN: "linkedin"
  },
  
  // UI elements
  UI: {
    RIBBON: "Union.svg",
    RIBBON_MOBILE: "ribbon_mob.svg",
    HAMBURGER_MENU: "ham-menu.svg",
    BLACK_DIAMOND: "black-diamond.svg",
    WHITE_DIAMOND: "diamond.svg",
    VOIS_LOGO: "VOIS_LOGO.png"
  },
  
  // Background images
  BACKGROUNDS: {
    HERO: "windmills.jpg",
    PROCESS: "processBG.png"
  }
};

/**
 * Helper function to get the full path to an asset
 * @param {string} basePath - The base path category
 * @param {string} asset - The asset name
 * @returns {string} The full path to the asset
 */
export const getAssetPath = (basePath, asset) => {
  return `${basePath}${asset}`;
};

/**
 * Get home asset path
 * @param {string} asset - Asset name or path
 * @returns {string} Full path to the home asset
 */
export const getHomeAssetPath = (asset) => {
  return getAssetPath(PATHS.HOME_ASSETS, asset);
};
