const createUtcDate = (time) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const today = new Date();
  return new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      hours,
      minutes,
      seconds,
      0
    )
  );
};

const formatTime = (time, isH23) => {
  return time.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    ...(isH23 && { hourCycle: "h23" }),
  });
};

const openLink = (link) => {
  window.open(link, "_blank");
};


export {createUtcDate, formatTime, openLink}