import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import httpService from "./../../services/httpService";
import {
  setUserJWT,
  ssoJWTCheck,
  getCurrentUser,
} from "./../../services/authService";
import CryptoJS from "crypto-js";
import { Formik } from "formik";
import * as Yup from "yup";
import NavBar from "../../components/common/navBar/navBar";
import axios from "axios";
// import LandingNavBar from "../home/navbar";

function LoginForm() {
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [RecaptchaToken, setRecaptchaToken] = useState("");
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(localStorage.getItem("IsSuperAdmin"));
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
  }, []);

  function SubmitRecaptcha(value) {
    // console.log("Captcha value:", value);
    setRecaptchaToken(value);
  }
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(5, "Minimum 5 characters")
      .max(255, "Maximum 5 characters")
      .required("Email is required")
      .email("Invalid email"),
    // .matches(
    //   "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
    //   "Come back to login as a Vodafone"
    // )
    password: Yup.string()
      .min(5, "Minimum 5 characters")
      .max(255, "Maximum 5 characters")
      .required("Password is required"),
  });

  // { Old login function}
  // const login = async ({ email, password }) => {
  //   console.log("email", email);
  //   console.log("password", password);
  //   try {
  //     const { data } = await httpService.post(
  //       `${process.env.REACT_APP_SERVER_URL}user/login`,
  //       {
  //         user: { email, password },
  //       },
  //       { headers: { RecaptchaHeader: RecaptchaToken } }
  //     );
  //     if (data) {
  //       setUserJWT(data.token);
  //       console.log("user email", getCurrentUser());
  //       history("/home");
  //     }
  //   } catch (err) {
  //     if (err.response.status === 403) {

  //       setServerErrorMessage(
  //         "Please refresh the page and verify you are not a robot."
  //       );
  //     } else {
  //       setServerErrorMessage(err.response.data);
  //     }
  //   }
  // };

  const login = async ({ email, password }) => {
    httpService
      .post(
        `${process.env.REACT_APP_SERVER_URL}auth`,
        {
          email,
          password,
        },
        { headers: { RecaptchaHeader: RecaptchaToken } }
      )
      .then((res) => {
        console.log("ressss:", res);
        const user = setUserJWT(res.data);
        console.log("user:::", user);

        if (user.status === "approved") {
          history("/entrance");
        }
        if (user.status === "pending") {
          setServerErrorMessage(
            "Please check your email, confirmation will be received soon"
          );
        }
      })
      .catch((err) => {
        console.log("errrooorr:", err);
        if (err.response) {
          switch (err.response.status) {
            case 400:
              setServerErrorMessage(err.response.data?.replace(/[\[\]"]/g, ""));
              break;
            case 401:
              setServerErrorMessage("Session expired. Please log in again.");
              // Redirect to login
              window.location.href = "/login";
              break;
            // recaptcha error
            case 403:
              setServerErrorMessage("Session expired. log in agin");
              // Redirect to login
              window.location.href = "/login";
              break;
            case 404:
              setServerErrorMessage(err.response.data?.replace(/[\[\]"]/g, ""));
              break;
            default:
              setServerErrorMessage(
                "Something went wrong. pls try agian later"
              );
          }
        }
      });
  };

  return (
    <>
      <div className=' login-pageContainer container-fluid col-12'>
        {/* <LandingNavBar /> */}
        <Formik
          initialValues={{
            email: "",
            password: "",
            isChecked: false,
          }}
          validationSchema={LoginSchema}
          onSubmit={({ email, password }) => {
            const user = { email, password };

            const res = login(user);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            touched,
            errors,
            handleChange,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <main className='row login-form-wrapper'> */}
              <div className='form-group'>
                <div className='login-form-input-text form-group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    className='login-form-input form-control'
                    id='email'
                    name='email'
                    {...getFieldProps("email")}
                  />
                  {touched.email && errors.email ? (
                    <div className='alert alert-danger login-form-alert-customize'>
                      {errors.email}
                    </div>
                  ) : null}
                </div>
                <div className='login-form-input-text form-group'>
                  <label htmlFor='password'>Password</label>
                  <input
                    type='password'
                    className='login-form-input form-control'
                    id='password'
                    name='password'
                    {...getFieldProps("password")}
                  />
                  {touched.password && errors.password ? (
                    <div className='alert alert-danger login-form-alert-customize'>
                      {errors.password}
                    </div>
                  ) : null}
                </div>

                <div className='login-form-input-text'>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NavLink
                      to='/forgetPassword'
                      style={{ textDecorationLine: "none" }}
                    >
                      <div className='login-form-input-text mb-3'>
                        Forget Password{" "}
                      </div>
                    </NavLink>
                    {/* <NavLink
                      to='/changePassword'
                      style={{ textDecorationLine: "none" }}
                    >
                      <div className='login-form-input-text mb-3'>
                        Change Password{" "}
                      </div>
                    </NavLink> */}
                  </div>
                  {/* <div className='login-form-checkBox'>
                        <input
                          type='checkbox'
                          onChange={handleChange}
                          id='exampleCheck1'
                          name='isChecked'
                        />
                        {values.isChecked}

                        <label className='mx-2' htmlFor='exampleCheck1'>
                          Remember My Credentials
                        </label>
                      </div> */}
                  {RecaptchaToken !== "" ? (
                    <button type='submit' className='login-form-actionBtn'>
                      <span>Sign in</span>
                    </button>
                  ) : (
                    <span className='d-flex justify-content-center align-items-center'>
                      <ReCAPTCHA
                        sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                        onChange={SubmitRecaptcha}
                        theme='dark'
                      />
                    </span>
                  )}
                  {serverErrorMessage ? (
                    <div
                      class='alert alert-danger login-form-alert-customize'
                      style={{ textAlign: "center" }}
                      role='alert'
                    >
                      {serverErrorMessage}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* </main> */}
            </form>
          )}
        </Formik>
      </div>
      <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'></div>
    </>
  );
}

export default LoginForm;
