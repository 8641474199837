import styles from "./Timeline.module.css";

const VerticalLine = () => {
  return (
    <div className={styles.verticalLine}></div>
  );
};

const PhaseStartDate = ({ startDate, isLastPhase }) => {
  return (
    <div className={styles.phaseStartDate}>
      <div className={styles.phaseStartDateSquare}>
        <div className={styles.startDateText}>{startDate}</div>
      </div>

      {!isLastPhase && <VerticalLine />}
    </div>
  );
};

const HackathonPhase = ({
  startDate,
  phaseName,
  phaseStatement,
  isLastPhase,
}) => {
  return (
    <div className={styles.HackathonPhase}>
      <PhaseStartDate startDate={startDate} isLastPhase={isLastPhase} />

      <div className={styles.phaseNameAndStatement}>
        <div className={styles.phaseName}>{phaseName}</div>
        <div>{phaseStatement}</div>
      </div>
    </div>
  );
};

const Hackathon2025Phases = [
  {
    startDate: "28 FEB",
    phaseName: "Registration opens",
    phaseStatement: "At 09:00 AM GMT",
  },
  {
    startDate: "28 FEB",
    phaseName: "Registration closes",
    phaseStatement: "At 09:00 AM GMT",
  },
  {
    startDate: "28 FEB",
    phaseName: "Announcement of shortlisted teams",
    phaseStatement: "To join VOIS Hackathon",
  },
  {
    startDate: "28 FEB",
    phaseName: "Hackathon prep phase",
    phaseStatement: "Shortlisted teams to prepare for competition",
  },
  {
    startDate: "28 FEB",
    phaseName: "HACKATHON",
    phaseStatement: "Development days",
  },
  {
    startDate: "28 FEB",
    phaseName: "Jury solutions & Hackathon closure",
    phaseStatement: "",
  },
  {
    startDate: "28 FEB",
    phaseName: "Winners announcement ",
    phaseStatement: "Congratulations!",
  },
];

const Timeline = () => {
  return (
    <div id="timeline-section" className={styles.timelineContainer}>
      <h1 className="gradientHeader">Your hackathon timeline</h1>
      <div className={styles.HackathonPhases}>
        {Hackathon2025Phases.map((phase, index) => (
          <HackathonPhase
            startDate={phase.startDate}
            phaseName={phase.phaseName}
            phaseStatement={phase.phaseStatement}
            isLastPhase={
              index === Hackathon2025Phases.length - 1 ? true : false
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Timeline;