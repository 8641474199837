/**
 * Centralized configuration for sessions asset paths and external links
 */
export const PATHS = {
  ASSETS: {
    BASE: 'assets/2025/sessions',
    ICONS: {
      BACK: 'chevron-double-left.svg',
      AGENDA_ICON: "agenda-icon.svg",
      ARROW_DOWN: "arrow-down.svg"
    },
    LOGOS: {
      VOIS_LOGO_BLACK: 'black_vois_logo.svg',
      HACKATHON_LOGO: 'hackathon_logo.svg',
    },
    SOCIAL: {
      FACEBOOK: 'FB.svg',
      INSTAGRAM: 'IG.svg',
      LINKEDIN: 'LI.svg',
    },
  },
  EXTERNAL_LINKS: {
    VOIS_FACEBOOK: 'https://www.facebook.com/voisglobal/',
    VOIS_INSTAGRAM: 'https://www.instagram.com/voisglobal/',
    VOIS_LINKEDIN: 'https://www.linkedin.com/company/vois/mycompany/verification/',
    VOIS_CAREERS: 'https://www.vodafone.com/careers/professional-career-areas/shared-services#Work-with-us',
    VOIS_ABOUT: 'https://www.vodafone.com/about-vodafone/what-we-do/vodafone-business/vodafone-intelligent-solutions',
    VOIS_CONTACT_US: 'https://www.vodafone.com/about-vodafone/what-we-do/vodafone-business/vodafone-intelligent-solutions/contact-us',
  },
};

/**
 * Helper function to get the full path to a sessions asset
 * @param {string} assetPath - The relative path to the asset
 * @returns {string} The full path to the asset
 */
export const getAssetPath = (assetPath) => `${PATHS.ASSETS.BASE}/${assetPath}`;