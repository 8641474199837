import * as Yup from "yup";

const PitchFormScheme = Yup.object().shape({
  ideaName: Yup.string()
    .required("Please enter the pitch name")
    .min(5, "Minimum 5 characters")
    .max(50, "Maximum 50 characters"),
  description: Yup.string()
    .required("Please write the pitch description")
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 250 characters"),
  sector: Yup.array().min(1, "You should enter at least one sector"),
  technologies: Yup.array().min(1, "You should enter at least one technology"),
  teamMembers: Yup.array()
    .max(2, "You should enter 2 members max"),
});

export { PitchFormScheme };
