const openLink = (link) => {
  window.open(link, "_blank");
};

const checkLogInTime = (
  loggedInDate,
  agenda,
  setIsBeforeSession,
  setIsAfterSession,
  setloading
) => {
  if (agenda != undefined) {
    const logInTime = loggedInDate.getTime();
    const sessionStartTime = new Date(agenda[0].startDate).getTime();
    if (logInTime < sessionStartTime) {
      setIsBeforeSession(true);
    } else {
      setIsBeforeSession(false);
      setIsAfterSession(false);
      setloading(false);
    }
  } else {
    setIsAfterSession(true);
  }
};

export { openLink, checkLogInTime };
