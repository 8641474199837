import { useNavigate } from "react-router-dom";
import { Ribbon, SmallRibbon } from "../_shared/components/shapes";
import { useWindowWidth } from "../_shared/hooks/hooks";
import styles from "./Join.module.css";

const homeAssetsPath = "assets/2025/home/";

const Join = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  return (
    <>
      <SmallRibbon
        additionalStyling={{
          position: "sticky",
          marginBottom: -windowWidth / 16,
          marginTop: -windowWidth / 13.6,
        }}
        width={windowWidth}
      />
      <div className={styles.joinContainer}>
        <div className={styles.joinStatement}>
          <img src={`${homeAssetsPath}man.png`} alt="" />
          <div className={styles.joinStatementText}>
            <h1 className={`gradientHeader ${styles.joinHeader}`}>
              Join from your home
            </h1>
            <div className={styles.joinStatementDetails}>
              The hackathon is 100% online, make sure to have a comfortable
              place and good internet connectivity to join all sessions. ​In
              case you prefer to join the Hackathon from VOIS office, we will
              provide In-Office facilities. Details will be shared.
            </div>
            <button
              className={styles.actionBtn}
              onClick={() => navigate("/region")}
            >
              Register Now
            </button>
          </div>
        </div>

        <Ribbon
          additionalStyling={{ marginTop: -30, marginBottom: -72.8 }}
          width={windowWidth }
        />
      </div>
      <SmallRibbon
        additionalStyling={{
          position: "sticky",
          marginTop: -windowWidth / 12,
          marginBottom: -windowWidth / 17,
        }}
        width={windowWidth}
      />
    </>
  );
};

export default Join;
