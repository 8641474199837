import { useContext, useEffect, useState } from "react";
import { StepContext } from "../contexts/stepContext";
import { VerificationContext } from "../contexts/verificationContext";
import { MainUserContext } from "../../../../contexts/mainUserContext";

import { GoBackButton, SubmitButton } from "../components/Button";
import { Formik, Form, Field } from "formik";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import styles from "./CreateAccountForm.module.css";

import { getCreateAccountScheme } from "../validationSchema/CreateAccountYupSchema";
import {
  getCodebyEmail,
  getCodebyMobile,
  verifyEmailCode,
  verifyMobileCode,
} from "../apis/APIs";

/* Helpers */
const getInitialValues = (region) => {
  const mandatoryFields = {
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirm: "",
    verEmailCode: "",
  };

  const initialValues =
    region === "egypt"
      ? {
          ...mandatoryFields,
          arabicFullName: "",
          nationalID: "",
          verPhoneNumberCode: "",
        }
      : region === "romania"
      ? { ...mandatoryFields, birthDate: "", verPhoneNumberCode: "" }
      : region === "india"
      ? { ...mandatoryFields, birthDate: "" }
      : {};

  return initialValues;
};

/* Input fields */
const InputField = ({ name, type, label, placeHolder, setFieldValue }) => {
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const [verificationData, setVerificationData] =
    useContext(VerificationContext);
  const { isEmailVerified, isMobVerified } = verificationData;
  const [showPassword, setShowPassword] = useState(false);

  const isVerPhoneNoField = name === "verPhoneNumberCode";
  const isVerEmailField = name === "verEmailCode";
  const isPasswordField = type === "password";

  const disableField = isVerEmailField
    ? isEmailVerified
    : isVerPhoneNoField
    ? isMobVerified
    : false;

  useEffect(() => {
    if (userDetails[name] && setFieldValue)
      setFieldValue(name, userDetails[name]);
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={styles.inputFieldContainer}>
          <label className={styles.inputLabel}>{label}</label>
          {isPasswordField ? (
            <div className={styles.passwordFieldWrapper}>
              <input
                {...field}
                className={`${styles.inputField} ${styles.passwordField}`}
                onInput={(e) => {
                  setUserDetails({ ...userDetails, name: e.target.value });
                  handleInputFieldChange(
                    e,
                    field,
                    name,
                    userDetails,
                    setUserDetails,
                    verificationData,
                    setVerificationData
                  );
                }}
                type={showPassword ? "text" : "password"}
                placeholder={"" || placeHolder}
                disabled={disableField}
              />
              <button
                type="button"
                className={styles.passwordToggleBtn}
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "Hide password" : "Show password"}
                tabIndex="-1"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          ) : (
            <input
              {...field}
              className={styles.inputField}
              onInput={(e) => {
                setUserDetails({ ...userDetails, name: e.target.value });
                handleInputFieldChange(
                  e,
                  field,
                  name,
                  userDetails,
                  setUserDetails,
                  verificationData,
                  setVerificationData
                );
              }}
              type={type}
              placeholder={"" || placeHolder}
              disabled={disableField}
            />
          )}

          {meta.touched && meta.error && (
            <div className={styles.fieldErrorMsg}>{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

const InputFieldWithBtn = ({ name, type, label, placeHolder, action, handleBtnClick, setFieldValue }) => {
  return (
    <div className={styles.inputFieldWithBtnContainer}>
      <InputField
        name={name}
        type={type}
        label={label}
        placeHolder={placeHolder}
        setFieldValue={setFieldValue}
      />

      <div className={styles.inputFieldBtn} onClick={handleBtnClick}>
        {action}
      </div>
    </div>
  );
};

const VerificationCodeInputField = ({ name, handleClick, setFieldValue }) => {
  const [verificationData, _] = useContext(VerificationContext);

  const fieldName = name.charAt(0).toUpperCase() + name.slice(1);
  const status =
    name === "email"
      ? verificationData.emailVerStatus
      : verificationData.mobVerStatus;

  const isFieldVerified = status?.includes("Verified");

  return (
    <div className={styles.verCodeFieldContainer}>
      <InputFieldWithBtn
        name={"ver" + fieldName + "Code"}
        label={"Verification Code"}
        type={"text"}
        action={"Confirm"}
        handleBtnClick={handleClick}
        setFieldValue={setFieldValue}
      />
      <div
        className={
          isFieldVerified ? styles.succVerStatusMsg : styles.failVerStatusMsg
        }
      >
        {status}
      </div>
    </div>
  );
};

const BirthDatePicker = ({ userDetails, setUserDetails, setFieldValue }) => {
  const { birthDate } = userDetails;
  const defaultValue = dayjs().subtract(18, "year");

  const datePickerStyles = {
    backgroundColor: "white",
    width: "95%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderBottom: "1px solid #b8b8b8",
        borderRadius: "0",
      },
      "&:hover fieldset": { borderColor: "#b8b8b8" },
      "&.Mui-focused fieldset": {
        borderColor: "#e60000",
        borderBottom: "1px solid #e60000",
      },
      paddingRight: "10px",
      "& input": {
        padding: "10px 10px",
      },
    },
  };

  const handleDateChange = (value, form) => {
    const formattedBD = value.format("DD-MM-YYYY");
    form.setFieldValue("birthDate", formattedBD);
    setUserDetails({
      ...userDetails,
      birthDate: formattedBD,
    });
  };

  const fieldValue = !birthDate ? defaultValue : dayjs(birthDate, "DD-MM-YYYY");

  useEffect(() => {
    if (userDetails["birthDate"] && setFieldValue)
      setFieldValue("birthDate", userDetails["birthDate"]);
  }, []);

  return (
    <Field name="birthDate">
      {({ meta, form }) => (
        <div className={styles.inputFieldContainer}>
          <label className={styles.inputLabel} htmlFor="">
            Birth Date
          </label>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={datePickerStyles}
              defaultValue={fieldValue}
              onChange={(value) => handleDateChange(value, form)}
              shouldDisableDate={(date) => date.isAfter(defaultValue, "day")}
            />
          </LocalizationProvider>
          {meta.touched && meta.error && (
            <div className={styles.fieldErrorMsg}>{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

/* Input field + Verification field */
const ToBeVerifiedField = ({
  name,
  label,
  type,
  setFieldValue,
  handleVerifyClick,
  handleConfirmClick,
}) => {
  const clickVerify = "Click verify to send verification code";
  return (
    <div className={styles.toBeVerifiedContainer}>
      <div className={styles.toBeVerifiedFieldContainer}>
        <div className={styles.verifyDiv}>
          <InputFieldWithBtn
            name={name}
            label={label}
            type={type}
            action={"Verify"}
            handleBtnClick={handleVerifyClick}
            setFieldValue={setFieldValue}
          />
          <span className={styles.clickVerifyMob}>{clickVerify}</span>
        </div>

        <VerificationCodeInputField
          name={name}
          handleClick={handleConfirmClick}
          setFieldValue={setFieldValue}
        />
      </div>
      <span className={styles.clickVerify}>{clickVerify}</span>
    </div>
  );
};

/* Handlers */
const handleMobVerify = (phoneNo, region) => {
  getCodebyMobile(phoneNo, region);
};

const handleEmailVerify = (email) => {
  getCodebyEmail(email);
};

const handleMobConfirm = (
  phoneNo,
  code,
  verificationData,
  setVerificationData
) => {
  const isPhoneNoVerified = !verificationData.isMobVerified;
  const setMobVerStatus = (status) =>
    setVerificationData({
      ...verificationData,
      mobVerStatus: status,
      isMobVerified: status === "Verified!" ? true : false,
    });
  if (isPhoneNoVerified)
    verifyMobileCode(phoneNo, code)
      .then(() => {
        setMobVerStatus("Verified!");
      })
      .catch(() => setMobVerStatus("Wrong code!"));
};

const handleEmailConfirm = (
  email,
  code,
  verificationData,
  setVerificationData
) => {
  const isEmailVerified = !verificationData.isEmailVerified;
  const setEmailVerStatus = (status) =>
    setVerificationData({
      ...verificationData,
      emailVerStatus: status,
      isEmailVerified: status === "Verified!" ? true : false,
    });
  if (isEmailVerified)
    verifyEmailCode(email, code)
      .then(() => {
        setEmailVerStatus("Verified!");
      })
      .catch(() => setEmailVerStatus("Wrong code!"));
};

const handleInputFieldChange = (
  e,
  field,
  name,
  userDetails,
  setUserDetails,
  verificationData,
  setVerificationData
) => {
  field.onChange(e);

  const isVerPhoneNoField = name === "verPhoneNumberCode";
  const isVerEmailField = name === "verEmailCode";

  const { isMobVerified, isEmailVerified } = verificationData;

  setUserDetails({ ...userDetails, [name]: e.target.value });

  if (isVerPhoneNoField) {
    setVerificationData({
      ...verificationData,
      enteredPhoneNoVerCode: e.target.value,
      mobVerStatus: isMobVerified ? "Verified!" : "",
    });
  } else if (isVerEmailField) {
    setVerificationData({
      ...verificationData,
      enteredEmailVerCode: e.target.value,
      emailVerStatus: isEmailVerified ? "Verified!" : "",
    });
  }
};

/* Create account form */
const CreateAccountForm = ({
  hasArabicFulName,
  hasNationalID,
  hasPhoneVer,
  hasBirthDate,
}) => {
  const [verificationData, setVerificationData] =
    useContext(VerificationContext);
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const [step, setStep] = useContext(StepContext);

  const { phoneNumber, email, region } = userDetails;
  const { enteredEmailVerCode, enteredPhoneNoVerCode } = verificationData;

  const createAccountScheme = getCreateAccountScheme(region);
  const initialValues = getInitialValues(region);

  const handleSubmit = (verificationData) => {
    const { isEmailVerified, isMobVerified } = verificationData;

    if (region === "egypt") {
      if (isEmailVerified && isMobVerified) setStep(2);
    } else if (region === "india") {
      if (isEmailVerified) setStep(3);
    } else if (region === "romania") {
      if (isEmailVerified && isMobVerified) setStep(3);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createAccountScheme}
      onSubmit={() => handleSubmit(verificationData)}
    >
      {({ setFieldValue }) => (
        <Form className={styles.createAccountFormContainer}>
          <InputField
            name={"fullName"}
            label={"Full Name"}
            type={"text"}
            placeHolder={"Must be more than 5 characters"}
            setFieldValue={setFieldValue}
          />

          {hasArabicFulName && (
            <InputField
              name={"arabicFullName"}
              label={"Full Name In Arabic"}
              type={"text"}
              placeHolder={"Must be as written in Arabic as your national ID"}
              setFieldValue={setFieldValue}
            />
          )}

          {hasBirthDate && (
            <BirthDatePicker
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              setFieldValue={setFieldValue}
            />
          )}

          {hasNationalID && (
            <InputField
              name={"nationalID"}
              label={"National ID"}
              type={"text"}
              setFieldValue={setFieldValue}
            />
          )}

          <ToBeVerifiedField
            name={"email"}
            label={"Email"}
            type={"email"}
            setFieldValue={setFieldValue}
            handleVerifyClick={() => handleEmailVerify(email)}
            handleConfirmClick={() =>
              handleEmailConfirm(
                email,
                enteredEmailVerCode,
                verificationData,
                setVerificationData
              )
            }
          />
          {hasPhoneVer ? (
            <ToBeVerifiedField
              name={"phoneNumber"}
              label={"Phone Number"}
              type={"text"}
              setFieldValue={setFieldValue}
              handleVerifyClick={() => handleMobVerify(phoneNumber, region)}
              handleConfirmClick={() =>
                handleMobConfirm(
                  phoneNumber,
                  enteredPhoneNoVerCode,
                  verificationData,
                  setVerificationData
                )
              }
            />
          ) : (
            <InputField
              name={"phoneNumber"}
              label={"Phone Number"}
              type={"text"}
              setFieldValue={setFieldValue}
            />
          )}

          <InputField
            name={"password"}
            label={"Password"}
            type={"password"}
            setFieldValue={setFieldValue}
          />
          <InputField
            name={"passwordConfirm"}
            label={"Password Confirm"}
            type={"password"}
            setFieldValue={setFieldValue}
          />
          <div className={styles.btnsGp}>
            <SubmitButton text={"Create Account"} />
            <GoBackButton
              text={"Back"}
              step={step}
              setStep={setStep}
              region={region}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
