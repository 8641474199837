import SessionFooter from "../SessionFooter/SessionFooter";
import SessionNavBar from "../SessionNavBar/SessionNavBar";
import styles from "./FinishedSession.module.css";

const FinishedSession = () => {
  return (
    <div className={styles["finished-session-container"]}>
      <SessionNavBar />
      <div className={styles["finished-session-msg"]}>
        <h3 className={styles["msg"]}>Your live session has been finished!</h3>
      </div>
      <SessionFooter />
    </div>
  );
};

export default FinishedSession;
