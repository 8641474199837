const handleSubmittedPitch = async (userDetails) => {
  const {
    email,
    phoneNumber,
    fullName,
    password,
    consentCheck,
    PDFConsent,
    ideaName,
    technologies,
    sector,
    teamMembers,
    description,
    nationalID,
    arabicFullName,
    birthDate,
    region,
    presentationFile,
  } = userDetails;

  /* Admin Object */
  let admin;

  const baseAdmin = {
    isAdmin: true,
    email: email,
    phoneNumber: phoneNumber,
    name: fullName,
    password: password,
    participatedBefore: false,
  };

  const egyptianAdmin = {
    ...baseAdmin,
    nameNationalId: arabicFullName,
    nationalId: nationalID,
  };

  const nonEgyptianAdmin = {
    ...baseAdmin,
    birthDate: birthDate,
  };

  if (region == "egypt") admin = egyptianAdmin;
  else admin = nonEgyptianAdmin;

  /* Consent Object */
  const consent = {
    consentCheck,
    consentForm: PDFConsent,
  };

  /* Pitch Object */
  const project = {
    projectName: ideaName,
    projectDesc: description,
    ideaTech: technologies,
    ideaType: sector,
    teamEmails: teamMembers,
    region: region,
    video: await fileToBase64(presentationFile),
  };

  return { admin, consent, project };
};

const appendFormData = (formData, data, parentKey = "") => {
  Object.entries(data).forEach(([key, value]) => {
    const formKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof File) {
      formData.append(formKey, value);
    } else if (typeof value === "object" && value !== null) {
      appendFormData(formData, value, formKey);
    } else {
      formData.append(formKey, value);
    }
  });
};

const getErrorMsg = (errMsgsArr) => {
  const errorMap = {
    email: "Email",
    "phone number": "Phone number",
    "project name": "Idea name",
  };

  const matchedErrors = Object.keys(errorMap)
    .filter((key) => errMsgsArr.some((msg) => msg.includes(key)))
    .map((key) => errorMap[key]);

  if (matchedErrors.length === 0) return "";

  return `${matchedErrors.join(" and ")} already registered, please change ${
    matchedErrors.length > 1 ? "them" : "it"
  }.`;
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(""); // Return empty string if no file
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export { getErrorMsg, appendFormData, handleSubmittedPitch, fileToBase64 };
