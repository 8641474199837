import * as Yup from "yup";

const checkIdDate = (value) => {
  let result =
    value.substr(1, 2) && value.substr(3, 2) < 13 && value.substr(5, 2) < 32;
  return result;
};

const calculateAge = (value) => {
  const nationalIDYear =
    value.toString().charAt(1) == "0" ||
    value.toString().charAt(1) == "1" ||
    value.toString().charAt(1) == "2"
      ? "20" + value.toString().substring(1, 3)
      : "19" + value.toString().substring(1, 3);

  const birth = new Date(
    `${value.toString().substring(3, 5)}/${value
      .toString()
      .substring(5, 7)}/${nationalIDYear}`
  );
  const date_diff =
    new Date(
      `${value.toString().substring(3, 5)}/${value
        .toString()
        .substring(5, 7)}/${nationalIDYear}`
    ).getTime() - new Date().getTime();
  const years = Math.abs(new Date(date_diff).getFullYear() - 1970);

  if (years < 18) {
    return false;
  } else if (years == 18) {
    if (birth.getMonth() < new Date().getMonth()) {
      return true;
    }
    if (birth.getMonth() == new Date().getMonth()) {
      if (birth.getDate() < new Date().getDate()) {
        return true;
      } else if (birth.getDate() == new Date().getDate()) {
        return false;
      }
    }
  } else if (years > 18) {
    return true;
  }
};

const CreateAccountBaseScheme = Yup.object().shape({
  fullName: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(100, "Maximum 100 characters")
    .required("Please enter your full name"),

  email: Yup.string()
    .min(7, "Minimum 7 characters")
    .max(150, "Maximum 150 characters")
    .required("Please enter your email")
    .email("Invalid email Format")
    .matches(
      "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
      "Please enter a valid email"
    ),
  password: Yup.string()
    .required("Provide a password")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~@#$^*()_+=[\]{}|\\,.?: -\!\%\&]).{8,}$/,
      "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  passwordConfirm: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Must match Password"),
  phoneNumber: Yup.string()
    .length(10, "Must be exactly 10-digit")
    .required("Please enter your phone number")
    .matches("(1)[0-9]{9}", " please enter a valid phone number"),

  verEmailCode: Yup.string()
    .required("Please verify")
    .length(4, "Must be 4-digit"),
});

const EgyptCreateAccountScheme = CreateAccountBaseScheme.shape({
  arabicFullName: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(100, "Maximum 100 characters")
    .matches(
      /[ء-ي]+/,
      "Provide your name written in Arabic as your national ID"
    )
    .required("Please enter your full name in Arabic"),
  nationalID: Yup.string()
    .length(14, "Must be 14-digit")
    .required("Please enter your national ID")
    .matches(/^[0-9]+$/, "Not a national ID number")
    .test("Validate National ID", "Invalid national ID", function (value) {
      return checkIdDate(value);
    })
    .test(
      "Validate participant's age",
      "You must be more than 18 to be able to register",
      function (value) {
        return calculateAge(value);
      }
    ),
  verPhoneNumberCode: Yup.string()
    .required("Please verify")
    .length(4, "Must be 4-digit"),
});

const IndiaCreateAccountScheme = CreateAccountBaseScheme.shape({
  birthDate: Yup.string().required("Please enter your birth date"),
});

const RomaniaCreateAccountScheme = CreateAccountBaseScheme.shape({
  birthDate: Yup.string().required("Please enter your birth date"),
  verPhoneNumberCode: Yup.string()
    .required("Please verify")
    .length(4, "Must be 4-digit"),
});

const getCreateAccountScheme = (region) => {
  return region == "egypt"
    ? EgyptCreateAccountScheme
    : region == "india"
    ? IndiaCreateAccountScheme
    : region == "romania"
    ? RomaniaCreateAccountScheme
    : null;
};
export { getCreateAccountScheme };
