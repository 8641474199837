import { isMobile } from "react-device-detect";
import { PATHS, getHomeAssetPath } from "../config/paths";

export const SocialMediaIcon = ({ platformImg }) => {
  return isMobile ? (
    <img
      src={getHomeAssetPath(`${platformImg}_mob.svg`)}
      alt={`${platformImg} social media icon`}
    />
  ) : (
    <img
      src={getHomeAssetPath(`${platformImg}.svg`)}
      alt={`${platformImg} social media icon`}
    />
  );
};

export const HamMenu = ({ toggle, setToggle }) => {
  return (
    <img
      className='hamMenu'
      src={getHomeAssetPath(PATHS.UI.HAMBURGER_MENU)}
      onClick={() => setToggle((state) => !state)}
      width={44}
      alt='Navigation menu toggle'
      aria-label={toggle ? "Close menu" : "Open menu"}
      role='button'
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setToggle((state) => !state);
        }
      }}
    />
  );
};
