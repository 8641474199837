import styles from "./AgendaNavBar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import {
  HorizontalRedLine,
  HackathonLogo,
  Icon,
  BlackVOISLogo,
} from "../imgsAndSVGs/ImgsAndSVGs";

const Breadcrumb = () => {
  return (
    <div className={styles["breadcrumb"]}>
      <NavLink className={styles["breadcrumb-hall"]} to={"/hall"}>
        3D Hall
      </NavLink>
      <span className={styles["breadcrumb-text"]}>/</span>
      <span className={styles["breadcrumb-text"]}>Agenda</span>
    </div>
  );
};

const AgendaBackBtn = () => {
  const history = useNavigate();
  return (
    <div className={styles["back-btn"]} onClick={() => history("/hall")}>
      <Icon imgName={"chevron-double-left.svg"} width={25} height={25} />
      <span>Back</span>
    </div>
  );
};

const AgendaNavBar = () => {
  return (
    <>
      <div className={styles["agenda-navbar"]}>
        <AgendaBackBtn />
        <div className={styles["logos-container"]}>
          <BlackVOISLogo />
          <HackathonLogo styles={styles} />
        </div>
      </div>
      <HorizontalRedLine />
      <Breadcrumb />
    </>
  );
};

export default AgendaNavBar;
