const regions = [
  {
    name: "egypt",
    flagPath: "assets/2025/registration/flags/egypt.png",
  },
  {
    name: "india",
    flagPath: "assets/2025/registration/flags/india.png",
  },
  {
    name: "romania",
    flagPath: "assets/2025/registration/flags/romania.png",
  },
];

const technologiesSuggestions = [
  "HTML5/CSS3",
  "NodeJs",
  "MongoDB",
  "MySQL",
  "PostgreSQL",
  "Python",
  "PHP",
  ".Net",
  "Java",
  "C/C++",
  "Ruby on Rails",
  "Unity",
  "R",
  "JavaScript",
  "AngularJs",
  "Angular",
  "TypeScript",
  "ReactJs",
  "VueJs",
  "React Native",
  "Kotlin",
  "Flutter",
  "Xamarin",
  "Ionic",
  "PhoneGap",
  "iOS",
  "Objective C",
  "Swift",
  "Android",
  "Docker",
  "Electron",
  "Rust",
  "Scala",
  "Go",
  "Sketch",
  "InVision Studio",
  "Proto.io",
  "Adobe XD",
  "Marvel",
  "Figma",
];

export { regions, technologiesSuggestions };
