import styles from "./SessionNavBar.module.css";
import { useNavigate } from "react-router-dom";
import {
  HackathonLogo,
  Icon,
  BlackVOISLogo,
} from "../imgsAndSVGs/ImgsAndSVGs";



const AgendaBackBtn = () => {
  const history = useNavigate();
  return (
    <div className={styles["back-btn"]} onClick={() => history("/hall")}>
      <Icon imgName={"chevron-double-left.svg"} width={25} height={25} />
      <span>Back</span>
    </div>
  );
};

const SessionNavBar = () => {
  return (
    <>
      <div className={styles["session-navbar"]}>
        <AgendaBackBtn />
        <div className={styles["logos-container"]}>
          <BlackVOISLogo />
          <HackathonLogo />
        </div>
      </div>
    </>
  );
};

export default SessionNavBar;
