import { useContext, useEffect, useState, useRef, memo } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
} from "@mui/material";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import { SubmitButton } from "../components/Button";

import styles from "./GetStartedForm.module.css";
import { StepContext } from "../contexts/stepContext";
import { regions } from "../constants/constants";

/* Radio & checkbox labels */
const RegionRadio = memo(({ regionName, flagPath, prevSelectedVal }) => {
  return (
    <div className={styles.regionFCL}>
      <FormControlLabel
        value={regionName}
        control={
          <Radio
            sx={{
              color: "#b8b8b8",
              "&.Mui-checked": {
                color: "#e60000",
              },
            }}
            checked={regionName === prevSelectedVal}
          />
        }
        label={<span className={styles.countryLabel}>{regionName}</span>}
      />

      <img src={flagPath} width={24} height={24} alt="flag" />
    </div>
  );
});

const TermsAndCondsCheckbox = memo(({ text, checkState, setCheckState }) => {
  const handleChange = () => {
    setCheckState(!checkState);
  };

  const checkboxId = `checkbox-${text.trim().replace(/\s+/g, '-')}`;

  return (
    <div>
      <Checkbox
        id={checkboxId}
        sx={{
          color: "#b8b8b8",
          "&.Mui-checked": {
            color: "#e60000",
          },
        }}
        checked={checkState}
        onChange={handleChange}
        aria-checked={checkState}
        inputProps={{ 'aria-label': `Agree to the ${text}` }}
      />
      <label htmlFor={checkboxId} className={styles.checkboxLabel}>
        I agree to the{" "}
        <a className={styles.link} href="" aria-label={`Read ${text}`}>
          {text}
        </a>
      </label>
    </div>
  );
});

/* Region radio group */
const RegionRadioGroup = () => {
  const [userDetails, changeDetails] = useContext(MainUserContext);
  const { region } = userDetails;

  const handleChange = (e) => {
    changeDetails({ ...userDetails, region: e.target.value });
  };

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      onChange={handleChange}
    >
      {regions.map((region, index) => (
        <RegionRadio
          key={index}
          regionName={region.name}
          flagPath={region.flagPath}
          prevSelectedVal={userDetails.region}
        />
      ))}
    </RadioGroup>
  );
};

/* Get Started form */
const GetStartedForm = () => {
  const [, setStep] = useContext(StepContext);
  const [userDetails, setUserDetails] = useContext(MainUserContext);
  const { region } = userDetails;

  // Initialize checkbox states from context if available
  const [termsAndCondsCheck, setTermsAndCondsCheck] = useState(
    userDetails.termsAccepted || false
  );
  const [privacyNoticeCheck, setPrivacyNoticeCheck] = useState(
    userDetails.privacyAccepted || false
  );

  const [radioError, setRadioError] = useState("");
  const [checkError, setCheckError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!region) {
      setRadioError(true);
      return;
    }

    if (!(termsAndCondsCheck && privacyNoticeCheck)) {
      setCheckError(true);
      return;
    }

    setUserDetails({ ...userDetails, getStartedChecks: true });
    setStep(1);
  };

  // Update error state when checkbox states change
  useEffect(() => {
    setCheckError(false);
    const checksState = userDetails.getStartedChecks;

    if (checksState) {
      setPrivacyNoticeCheck(true);
      setTermsAndCondsCheck(true);
    }
  }, [termsAndCondsCheck, privacyNoticeCheck, userDetails]);

  return (
    <form className={styles.getStartedForm} onSubmit={handleSubmit} aria-labelledby="registration-form-title">
      <div id="registration-form-title" className="sr-only">Registration Get Started Form</div>
      <FormControl error={radioError} required aria-required="true" aria-invalid={radioError ? "true" : "false"}>
        <RegionRadioGroup />
        {radioError && !region && (
          <span className={styles.radioError} role="alert">Region not selected!</span>
        )}
      </FormControl>

      <div className={styles.TCChecks}>
        <TermsAndCondsCheckbox
          text={" terms & conditions"}
          checkState={termsAndCondsCheck}
          setCheckState={setTermsAndCondsCheck}
        />
        <TermsAndCondsCheckbox
          text={" privacy notice"}
          checkState={privacyNoticeCheck}
          setCheckState={setPrivacyNoticeCheck}
        />
      </div>
      {checkError && (
        <span className={styles.radioError} role="alert">Please check both to proceed</span>
      )}
      <SubmitButton text={"Next Step"} />
    </form>
  );
};

export default GetStartedForm;
