import { createContext, useState } from "react";

const VerificationContext = createContext([() => {}]);

const VerificationProvider = ({ children }) => {
  const [verificationData, setVerificationData] = useState({
    enteredEmailVerCode: "",
    enteredPhoneNoVerCode: "",
    mobVerStatus: "",
    emailVerStatus: "",
  });


  return (
    <VerificationContext.Provider value={[verificationData, setVerificationData]}>
      {children}
    </VerificationContext.Provider>
  );
};

export { VerificationContext, VerificationProvider };