import SessionNavBar from "../SessionNavBar/SessionNavBar";
import Countdown from "react-countdown";
import moment from "moment";

import styles from "./BeforeSession.module.css"
import { UI_CONSTANTS } from "../../config/constants";

const BeforeSession = ({ date, renderer, handleCountdownCompletion }) => {
  const sessionStartDateFormatted = `${moment(date).format(UI_CONSTANTS.DATE_FORMAT)}`

  return (
    <>
      <div className={styles["before-session-container"]}>
        <SessionNavBar />
        <div
          className={styles["countdown-container"]}
          style={{ minHeight: UI_CONSTANTS.SESSION_MIN_HEIGHT }}
        >
          <Countdown
            date={new Date(date)}
            renderer={renderer}
            onComplete={handleCountdownCompletion}
          />
          <h3 className={styles["before-session-msg"]}>
            Your session will start soon, get back{" "}
            {sessionStartDateFormatted}
          </h3>
        </div>
      </div>
    </>
  );
};

export default BeforeSession;
