/**
 * Configuration file for agenda asset paths
 * This centralizes all path definitions to make maintenance easier
 */

export const PATHS = {
  // Base paths
  AGENDA_ASSETS: "assets/2025/agenda/",
  
  // Icons
  ICONS: {
    AGENDA: "agenda_icon.svg",
    CHEVRON_LEFT: "chevron-double-left.svg",
    BLACK_CHEVRON: "black_chevron.svg",
    WHITE_CHEVRON: "white_chevron.svg",
    BACK_ARROW: "back-arrow.svg"
  },
  
  // Logos
  LOGOS: {
    VOIS_LOGO_BLACK: "black_vois_logo.svg",
    VOIS_LOGO: "vois_logo_black.svg"
  },
  
  // Social Media
  SOCIAL: {
    FACEBOOK: "FB.svg",
    INSTAGRAM: "IG.svg",
    LINKEDIN: "LI.svg"
  }
};

/**
 * Helper function to get the full path to an agenda asset
 * @param {string} asset - The asset name
 * @returns {string} The full path to the asset
 */
export const getAgendaAssetPath = (asset) => {
  return `${PATHS.AGENDA_ASSETS}${asset}`;
};
