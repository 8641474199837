import React from "react";

function NotApproved() {
  return (
    <div className='container-fluid success-submission-container '>
      <main className='success-main row '>
        <div className='success-main-content  col-sm-12 text-center'>
          <div className='success-message'>
            <p className='success-message-note'>
              Please check your email, confirmation will be received soon.
            </p>

            {/* <button
              type='submit'
              className='login-btn d-flex justify align-content-center'
              onClick={() => {
                history("/login");
              }}
            >
              <span>Login</span>
            </button> */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotApproved;
