import styles from "./Submission.module.css";
import { ArrowBack } from "@mui/icons-material";

const Submission = ({ header, details, imageName, btnAction, handleClick }) => {
  return (
    <div className={styles.SSContainer}>
      <img
        src={`assets/2025/registration/${imageName}.png`}
        width={110}
        height={100}
        alt=""
      />
      <h1 className={styles.header}>{header}!</h1>
      <div className={styles.details}>{details}</div>
      <div className={styles.actionBtn} onClick={handleClick}>
        <ArrowBack/>
        {btnAction}
      </div>
    </div>
  );
};

export default Submission;
