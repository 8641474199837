import { Ribbon } from "../_shared/components/shapes";
import { useWindowWidth } from "../_shared/hooks/hooks";
import styles from "./Theme.module.css";

const Theme = () => {
  const windowWidth = useWindowWidth();
  return (
    <>
      <Ribbon additionalStyling={{ marginTop: -23 }} width={windowWidth} />
      <div className={styles.themeContainer}>
        <img className={styles.sunsetImg} src="assets/2025/home/sunset.png" alt="" />
        <div className={styles.themeStatementContainer}>
          <h1 className={`gradientHeader ${styles.themeHeader}`}>Your Hackathon theme</h1>

          <div className={styles.themeStatement}>embracing ai in business</div>

          <div className={styles.themeDetails}>
            AI is revolutionizing various aspects of all kinds of business. It
            is optimizing operations through automation, enhancing customer
            experiences with personalized recommendations, and enabling
            data-driven decision making for better strategies.​ <br/>​ <br/>​ In Hackathon
            2024, we are expecting to witness the application of creativity,
            problem-solving, and coding skills as AI solutions shape the world.
            This event will be open to all developers and will offer a unique
            opportunity to contribute to the future.
          </div>
        </div>
      </div>
    </>
  );
};

export default Theme;
